export const SET_CURRENT_STEP = "app/SET_CURRENT_STEP";
export const SET_VERIFIED_STEP = "app/SET_VERIFIED_STEP";
export const SET_CONFIRM_EMAIL = "app/SET_CONFIRM_EMAIL";
export const SET_PARAMS = "app/SET_PARAMS";
export const SET_TOKENS = "app/SET_TOKENS";
export const SET_FORM_TYPE = "app/SET_FORM_TYPE";
export const RESET_CACHED_DATA = "app/RESET_CACHED_DATA";
export const SET_HIDE_NAVIGATOR = "app/SET_HIDE_NAVIGATOR";
export const SET_TRANSACTION_DATA = "app/SET_TRANSACTION_DATA";

export function setTokens(payload) {
  return {
    type: SET_TOKENS,
    payload,
  };
}

export function resetCache() {
  return {
    type: RESET_CACHED_DATA,
  };
}

export function setCurrentStep(payload) {
  return {
    type: SET_CURRENT_STEP,
    payload,
  };
}
export function setTransactionData(payload) {
  return async (dispatch) => {
    const uuid = await window.localStorage.getItem("uuid");
    await window.localStorage.setItem(`token_${uuid}`, payload.token);

    dispatch({
      type: SET_TRANSACTION_DATA,
      payload,
    });
  };
}

export function setVerifiedStep(payload) {
  return {
    type: SET_VERIFIED_STEP,
    payload,
  };
}

export function setFormType(payload) {
  return {
    type: SET_FORM_TYPE,
    payload,
  };
}

export function setHideNavigator(payload) {
  return {
    type: SET_HIDE_NAVIGATOR,
    payload,
  };
}

export function setParams(data) {
  return async (dispatch) => {
    let payload = {};
    if (data.length > 0) {
      data.forEach((el) => {
        payload = { ...payload, [el.name]: el.value };
      });
      dispatch({
        type: SET_PARAMS,
        payload,
      });
    }
  };
}

export function setConfirmEmail(payload) {
  return async (dispatch) => {
    const uuid = await window.localStorage.getItem("uuid");
    await window.localStorage.setItem(`token_${uuid}`, payload.token);

    dispatch({
      type: SET_CONFIRM_EMAIL,
      payload,
    });
  };
}
