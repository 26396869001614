import {
  RESET_CACHED_DATA,
  SET_CONFIRM_EMAIL,
  SET_CURRENT_STEP,
  SET_FORM_TYPE,
  SET_HIDE_NAVIGATOR,
  SET_PARAMS,
  SET_TOKENS,
  SET_TRANSACTION_DATA,
  SET_VERIFIED_STEP,
} from "_redux/actions/app";

let initialState = {
  currentStep: 0,
  verifiedSteps: [],
  calculation: null,
  transaction_id: null,
  shop_name: null,
  cart_items: null,
  loaded: false,
  form_params: {},
  token1: null,
  token2: null,
  formType: "NATURAL_PERSON",
  hideNavigator: false,
  shop_host: null,
  offer_number: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };

    case SET_VERIFIED_STEP:
      return {
        ...state,
        verifiedSteps: [...state.verifiedSteps, action.payload],
      };

    case SET_CONFIRM_EMAIL:
      return {
        ...state,
        calculation: action.payload.calculation,
        transaction_id: action.payload.transaction_id,
        shop_name: action.payload.shop_name,
        cart_items: action.payload.cart_items,
        shop_host: action.payload.shop_host,
        shop_uuid: action.payload.shop_uuid,
        offer_number: action.payload.offer_number,
        loaded: true,
      };

    case SET_TRANSACTION_DATA:
      return {
        ...state,
        transaction_id: action.payload.transaction_id,
        transaction_person_name: action.payload.transaction_person_name,
        transaction_person_surname: action.payload.transaction_person_surname,
        transaction_uuid: action.payload.transaction_uuid,
      };

    case SET_PARAMS:
      return {
        ...state,
        form_params: { ...state.form_params, ...action.payload },
      };

    case SET_FORM_TYPE:
      return {
        ...state,
        formType: action.payload,
      };

    case SET_TOKENS:
      return {
        ...state,
        token1: action.payload.token1,
        token2: action.payload.token2,
      };

    case RESET_CACHED_DATA:
      return {
        ...state,
        calculation: null,
        transaction_id: null,
        shop_name: null,
        cart_items: null,
        loaded: false,
        form_params: {},
        token1: null,
        token2: null,
        formType: "LEGAL_PERSON",
        hideNavigator: false,
        form: {},
      };

    case SET_HIDE_NAVIGATOR:
      return {
        ...state,
        hideNavigator: action.payload,
      };
    default:
      return state;
  }
}
