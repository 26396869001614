import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import styles from "./styles/agreement.module.scss";
import { setCurrentStep } from "_redux/actions/app";
import "./styles/pdf-overrides.css";

const NoMatch = memo(() => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentStep(1));
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div>
      <div className={styles.agreementGet}>
        <h3 className={styles.title}>Brak danych sklepu</h3>
      </div>
    </div>
  );
});

export default NoMatch;
