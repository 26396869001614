import React, { memo, useState } from "react";
import styles from "./styles/checkbox.module.scss";
import cx from "classnames";

const Checkbox = memo(
  ({ label, checked, htmlLabel, onChange, showAll, required, errored }) => {
    const [collapsed, setCollapsed] = useState(false);
    const toggleCollapsed = () => setCollapsed((prev) => !prev);
    const canBeCollapsed = htmlLabel
      ? htmlLabel.length > 300
      : label.length > 80;

    const change = (e) => {
      onChange(e.target.checked);
    };

    return (
      <div
        className={cx(styles.container, {
          [styles.collapsed]: (canBeCollapsed && collapsed) || showAll,
          [styles.withoutShadow]: !canBeCollapsed || showAll,
        })}
      >
        <label
          className={cx(styles.content, {
            [styles.required]: errored,
          })}
        >
          <input
            type={"checkbox"}
            name={"name"}
            checked={checked}
            onChange={change}
            required={required}
          />
          {!htmlLabel && (
            <span className={styles.text}>
              <p>{label}</p>
            </span>
          )}
          {htmlLabel && (
            <span className={styles.text}>
              <p dangerouslySetInnerHTML={{ __html: htmlLabel }}></p>
            </span>
          )}
          <span className={styles.checkmark} />
        </label>
        {canBeCollapsed && (
          <span className={styles.collapse} onClick={toggleCollapsed} />
        )}
      </div>
    );
  }
);
Checkbox.displayName = "Checkbox";

Checkbox.defaultProps = {
  label: "name",
  htmlLabel: null,
  checked: false,
  onChange: () => {},
  showAll: false,
  errored: false,
  required: false,
};

export default Checkbox;
