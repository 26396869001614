import React, { memo } from "react";
import styles from "./styles/loader.module.scss";

const Loader = memo(({ size }) => {
  return (
    <div className={styles.container} data-size={size}>
      <div className={styles.loader} />
    </div>
  );
});
Loader.displayName = "Loader";
Loader.defaultProps = {
  size: "",
};

export default Loader;
