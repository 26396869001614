import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import cx from "classnames";

import styles from "./styles/calc.module.scss";
import {
    resetCache,
    setConfirmEmail,
    setCurrentStep,
} from "_redux/actions/app";
import {requestGetProducts} from "_api/products";
import Select from "react-select";
import {pathOr} from "ramda";
import "./styles/select-overrides.css";
import {
    requestCalculateParams,
    requestConfirmEmail,
    requestSendEmail,
} from "_api/calculator";
import TextInput from "_components/TextInput";
import Button from "_components/Button";
import {useHistory, useParams} from "react-router";
import CheckMark from "_components/CheckMark";
import Loader from "_components/Loader";
import {loadReCaptcha, ReCaptcha} from "react-recaptcha-v3";

import {CAPTCHA_SITE_KEY} from "utils/constants";
import {formatMoney, validateEmail} from "utils/functions";
import {requestUpdateCalc} from "_api/transaction";
import {useToasts} from "react-toast-notifications";

const Calc = memo(() => {
    const dispatch = useDispatch();
    const {token1, token2, uuid} = useParams();
    const {addToast} = useToasts();

    const [activeMonth, setActiveMonth] = useState(6);
    const [data, setData] = useState(null);
    const [mainProduct, setMainProduct] = useState(null);
    const [accessory, setAccessory] = useState([]);
    const [servicesBefore, setServicesBefore] = useState(null);
    const [servicesDuring, setServicesDuring] = useState(null);
    const [formattedRentParams, setFormattedRentParams] = useState(null);
    const [rentParams, setRentParams] = useState(null);
    const [userEmail, setUserEmail] = useState("");

    const [error, setError] = useState({status: false, message: ""});
    const [response, setResponse] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tempUrl, setTempUrl] = useState(null);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [editMode, setEditMode] = useState(false);

    const {transaction_id} = useSelector((state) => state.app);
    const history = useHistory();
    let recaptcha = null;

    useEffect(() => {
        if ((!token1 && !token2) || (token1 && !token2)) {
            getProducts(uuid, false);
        } else {
            setEditMode(true);
            getProducts(uuid, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token1, token2]);

    useEffect(() => {
        dispatch(resetCache());
        loadReCaptcha(CAPTCHA_SITE_KEY);
        dispatch(setCurrentStep(1));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        calculateRentParams({
            mainProduct,
            accessory,
            servicesBefore,
            servicesDuring,
            activeMonth,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessory, servicesBefore, servicesDuring, activeMonth, mainProduct]);

    const setInitialData = async (products) => {
        try {
            const data = await requestConfirmEmail({token1, token2});
            const response = pathOr(null, ["data"])(data);
            dispatch(setConfirmEmail(response));

            const {cart_items, calculation} = response;

            if (
                cart_items &&
                cart_items.main_items &&
                cart_items.main_items.length > 0
            ) {
                setMainProduct(
                    products.main.find((p) => p.id === cart_items.main_items[0].id)
                );
            }
            if (
                cart_items &&
                cart_items.accessories_items &&
                cart_items.accessories_items.length > 0
            ) {
                setAccessory(
                    products.accessories
                        .filter((p) =>
                            cart_items.accessories_items.find((el) => el.id === p.id)
                        )
                        .map((p) => {
                            const x = cart_items.accessories_items.find(
                                (el) => el.id === p.id
                            );
                            let quantity = 0;
                            if (x) {
                                quantity = x.quantity;
                            }
                            return {...p, quantity};
                        })
                );
            }
            if (
                cart_items &&
                cart_items.services_before_items &&
                cart_items.services_before_items.length > 0
            ) {
                setServicesBefore(
                    products.services_before_the_lease.find(
                        (p) => p.id === cart_items.services_before_items[0].id
                    )
                );
            }
            if (
                cart_items &&
                cart_items.services_during_items &&
                cart_items.services_during_items.length > 0
            ) {
                setServicesDuring(
                    products.services_during_the_lease.find(
                        (p) => p.id === cart_items.services_during_items[0].id
                    )
                );
            }
            const month = pathOr(6, ["data", "months"])(calculation);
            setActiveMonth(month);
        } catch (e) {
        }

        setData(products);
        setLoading(false);
    };

    const getProducts = async (uuid, edit = false) => {

        setLoading(true);
        const data = await requestGetProducts(uuid);
        const products = pathOr([], ["data"])(data);

        if (!edit) {
            setData(products);
            if (products) {

                if (token1 && !token2) {
                    setActiveMonth(24);
                    const selectedMain = (products.main || []).find(m => m.id === parseInt(token1));
                    setMainProduct(selectedMain);
                }
                setLoading(false);
            }
        } else {
            setInitialData(products);
        }
    };

    const calculateRentParams = async ({
                                           mainProduct,
                                           accessory,
                                           servicesBefore,
                                           servicesDuring,
                                           activeMonth,
                                       }) => {
        try {
            const params = await requestCalculateParams(
                mainProduct,
                activeMonth,
                accessory,
                servicesBefore,
                servicesDuring,
                uuid
            );
            const data = pathOr(null, ["data"])(params);
            const formattedData = pathOr(null, ["data_formatted"])(params);
            setFormattedRentParams(formattedData);
            setRentParams(data);
        } catch (e) {
            const message = pathOr(null, ["response", "data", "data"])(e);

            if (message) {
                addToast(message, {
                    appearance: "error",
                });
            }
        }
    };

    const setMonth = (num) => (e) => {
        setActiveMonth(num);
    };

    const onMainChange = (val) => {
        setMainProduct(val);
        calculateRentParams({
            mainProduct: val,
            accessory,
            servicesBefore,
            servicesDuring,
            activeMonth,
        });
    };

    const addQuantity = (val) => (e) => {
        setAccessory((prev) =>
            prev.map((el) =>
                el.id !== val.id ? el : {...el, quantity: el.quantity + 1}
            )
        );
    };

    const undoQuantity = (val) => (e) => {
        setAccessory((prev) =>
            prev.map((el) =>
                el.id !== val.id
                    ? el
                    : {...el, quantity: el.quantity > 1 ? el.quantity - 1 : el.quantity}
            )
        );
    };

    const onAccessoryChange = (index) => (val, type) => {
        if (type) {
            if (type.action === "clear") {
                setAccessory((prev) => prev.filter((el, i) => i !== index));
            } else if (type.action === "select-option") {
                setAccessory((prev) =>
                    prev.map((el, i) => (i === index ? {...val, quantity: 1} : el))
                );
            }
        }
    };
    const onAccessoryAdd = (val) => {
        setAccessory((prev) => [...prev, {...val, quantity: 1}]);
    };

    const onServicesBeforeChange = (val) => {
        setServicesBefore(val);
    };

    const onServicesDuringChange = (val) => {
        setServicesDuring(val);
    };

    const getNetPrice = (month_options) => {
        try {
            const el = month_options.find((m) => m.months === activeMonth);
            if (el) {
                return el.month_net_cost_formatted;
            }
        } catch (e) {
        }

        return 0;
    };

    const getGrossPrice = (month_options) => {
        try {
            const el = month_options.find((m) => m.months === activeMonth);
            if (el) {
                return el.month_gross_cost_formatted;
            }
        } catch (e) {
        }

        return 0;
    };

    const onEmailChange = (value) => {
        setUserEmail(value);
    };

    const updateData = async () => {
        setLoading(true);
        setError({status: false, message: ""});
        try {
            const data = await requestUpdateCalc(transaction_id, {
                rentParams,
                mainProduct,
                accessory,
                servicesBefore,
                servicesDuring,
            });

            const url = pathOr(null, ["data"])(data);
            if (url) {
                setTempUrl(url.replace("http://g4r.dev2.muchmore.pl", ""));
            }
            setResponse(true);

            history.push(`/confirm-email/${token1}/${token2}`);
        } catch (e) {
            catchErrors(e);
        }
        setLoading(false);
    };

    const sendEmail = async () => {
        if (recaptcha) {
            recaptcha.execute();
        }

        const validEmail = validateEmail(userEmail);
        setLoading(true);
        if (validEmail && rentParams) {
            if (mainProduct) {
                setError({status: false, message: ""});
                try {
                    const data = await requestSendEmail({
                        userEmail,
                        rentParams,
                        mainProduct,
                        accessory,
                        servicesBefore,
                        servicesDuring,
                        recaptcha: recaptchaToken,
                    });

                    const url = pathOr(null, ["data"])(data);
                    if (url) {
                        setTempUrl(url.replace("http://g4r.dev2.muchmore.pl", ""));
                    }
                    setResponse(true);
                } catch (e) {
                    catchErrors(e);
                }
            } else {
                setError({status: true, message: "Musisz wybrać przedmiot najmu!"});
            }
        } else {
            setError({status: true, message: "Niepoprawny adres email"});
        }
        setLoading(false);
    };

    const catchErrors = (e) => {
        const errors = pathOr([], ["response", "data", "errors"])(e);
        let message = "Bład podczas przesyłania danych";
        const captchaError = errors.find(
            (er) => er.invalid_property === "recaptcha"
        );
        const emailError = errors.find((er) => er.invalid_property === "c16");

        if (captchaError) {
            message = captchaError.message;
        } else if (emailError) {
            message = emailError.message;
        }

        setError({status: true, message});

        const msg = pathOr(null, ["response", "data", "data"])(e);

        if (msg) {
            addToast(msg, {
                appearance: "error",
            });
        }
    };

    const verifyCallback = (recaptchaToken) => {
        setRecaptchaToken(recaptchaToken);
    };

    const formatOptionLabel = ({
                                   image_url,
                                   name,
                                   month_options,
                                   gross_price,
                               }) => (
        <div className={styles.option}>
            <img
                src={image_url}
                className="hide-on-mobile"
                alt="g4r"
                loading="lazy"
            />
            <label>
                {name}
                <p>{`Wartość brutto: ${formatMoney(gross_price)} zł`}</p>
            </label>
            <div>
                <span>{`${getNetPrice(month_options)} zł`}</span>
                <span>{`${getGrossPrice(month_options)} zł`}</span>
            </div>
        </div>
    );

    const formatSubOptionLabel = ({image_url, name, month_options}) => (
        <div className={styles.subOption}>
            <img
                src={image_url}
                className={cx(styles.image, "hide-on-mobile")}
                alt="g4r"
                loading="lazy"
            />
            <label>{name}</label>
            <div className={styles.prices}>
                <span>{`${getNetPrice(month_options)} zł`}</span>
                <span>{`${getGrossPrice(month_options)} zł`}</span>
            </div>
        </div>
    );

    const formatSubOptionLabelQuantity = ({
                                              image_url,
                                              name,
                                              month_options,
                                              quantity = 1,
                                              value,
                                          }) => {
        return (
            <div className={styles.subOption}>
                <img
                    src={image_url}
                    className={cx(styles.image, "hide-on-mobile")}
                    alt="g4r"
                    loading="lazy"
                />
                <label>
                    <span>{name}</span>
                    {quantity > 0 && (
                        <span className={styles.quantity} onClick={addQuantity(value)}>
              ilość: {quantity}
            </span>
                    )}
                </label>
                <div className={styles.prices}>
                    {
                        quantity > 1 ?
                            <>
                                <span>{`${getNetPrice(month_options).replace(' ', '') * quantity} zł`}</span>
                                <span>{`${getGrossPrice(month_options).replace(' ', '') * quantity} zł`}</span>
                            </>
                            :
                            <>
                                <span>{`${getNetPrice(month_options)} zł`}</span>
                                <span>{`${getGrossPrice(month_options)} zł`}</span>
                            </>
                    }


                </div>
            </div>
        );
    };

    const wTreshold = 425;
    const size = useWindowSize();

    return (
        <div>
            {!response && <h3 className={styles.title}>Wybierz parametry oferty</h3>}
            {response && (
                <h3 className={styles.title}>
                    Na podany adres email wysłaliśmy link do podsumowania oferty oraz
                    formularza
                </h3>
            )}
            {!response && (
                <div>
                    <h4 className={styles.header}>Minimalny okres najmu</h4>
                    <div>
                        <ul className={cx(styles.months, styles[`active-${activeMonth}`])}>
                            <li onClick={setMonth(1)}>1 {size?.width > wTreshold ? <><br/> Miesiąc</> : 'mc'}</li>
                            <li onClick={setMonth(3)}>3 {size?.width > wTreshold ? <><br/> Miesiące</> : 'mce'}</li>
                            <li onClick={setMonth(6)}>6 {size?.width > wTreshold ? <><br/> Miesięcy</> : 'mcy'}</li>
                            <li onClick={setMonth(12)}>12 {size?.width > wTreshold ? <><br/> Miesięcy</> : 'mcy'}</li>
                            <li onClick={setMonth(24)}>24 {size?.width > wTreshold ? <><br/> Miesiące</> : 'mce'}</li>
                        </ul>
                    </div>
                    {!data && <Loader/>}
                    {data && (
                        <div>
                            <h4 className={styles.header}>
                                Główny przedmiot najmu
                                <div className={styles.valueLabel}>
                                    <span>Rata mies.</span>
                                    <div style={{textAlign: "center"}}>
                                        <div>netto</div>
                                        <div>brutto</div>
                                    </div>
                                </div>
                            </h4>
                            <div>
                                <Select
                                    formatOptionLabel={formatOptionLabel}
                                    defaultValue={null}
                                    options={data.main.map((m) => ({...m, value: m.name}))}
                                    onChange={onMainChange}
                                    noOptionsMessage={() => {
                                        return "Nie znaleziono wyników";
                                    }}
                                    inputOption={"Wybierz produkt"}
                                    classNamePrefix={"main-select"}
                                    isClearable={false}
                                    value={mainProduct}
                                    isSearchable={true}
                                    placeholder={"Wpisz nazwę szukanego produktu"}
                                />
                            </div>
                            <h4 className={styles.header}>Produkty i akcesoria dodatkowe</h4>
                            {accessory.map((acc, index) => (
                                <div key={index} className={styles.selectContainer}>
                                    <Select
                                        formatOptionLabel={formatSubOptionLabelQuantity}
                                        options={data.accessories
                                            .filter(
                                                (m) =>
                                                    !accessory.find((el) => el.id === m.id) ||
                                                    m.id === acc.id
                                            )
                                            .map((m) => ({
                                                ...m,
                                                value: m,
                                            }))}
                                        onChange={onAccessoryChange(index)}
                                        classNamePrefix={"sub-select"}
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={"Wybierz"}
                                        value={acc}
                                    />
                                    <div
                                        className={styles.quantityAdd}
                                        onClick={addQuantity(acc)}
                                    />
                                    <div
                                        className={cx(styles.quantityUndo, {
                                            [styles.quantityDisabled]: acc.quantity === 1,
                                        })}
                                        onClick={undoQuantity(acc)}
                                    />
                                </div>
                            ))}
                            <div>
                                <Select
                                    formatOptionLabel={formatSubOptionLabel}
                                    options={data.accessories
                                        .filter((m) => !accessory.find((el) => el.id === m.id))
                                        .map((m) => ({
                                            ...m,
                                            value: m.name,
                                        }))}
                                    onChange={onAccessoryAdd}
                                    classNamePrefix={"sub-select"}
                                    isClearable={true}
                                    isSearchable={true}
                                    placeholder={"Dodaj"}
                                    value={null}
                                />
                            </div>
                            <h4 className={styles.header}>Usługi dodatkowe przed wynajmem</h4>
                            <div>
                                <Select
                                    formatOptionLabel={formatSubOptionLabel}
                                    options={data.services_before_the_lease.map((m) => ({
                                        ...m,
                                        value: m,
                                    }))}
                                    value={servicesBefore}
                                    onChange={onServicesBeforeChange}
                                    classNamePrefix={"sub-select"}
                                    isClearable={true}
                                    isSearchable={false}
                                    placeholder={"Wybierz"}
                                />
                            </div>
                            <h4 className={styles.header}>
                                Usługi dodatkowe w trakcie trwania wynajmu
                            </h4>
                            <div>
                                <Select
                                    formatOptionLabel={formatSubOptionLabel}
                                    options={data.services_during_the_lease.map((m) => ({
                                        ...m,
                                        value: m,
                                    }))}
                                    value={servicesDuring}
                                    onChange={onServicesDuringChange}
                                    classNamePrefix={"sub-select"}
                                    isClearable={true}
                                    isSearchable={false}
                                    placeholder={"Wybierz"}
                                />
                            </div>
                            {formattedRentParams && (
                                <>
                                    <div className={styles.totalValue}>
                                        <div className={styles.valueTitle}>
                                            Łączna rata miesięczna netto <span> brutto</span>
                                        </div>
                                        <div className={styles.values}>
                                            <div>{`${formattedRentParams.net_month_cost} zł`}</div>
                                            <div>{`${formattedRentParams.gross_month_cost} zł`}</div>
                                        </div>
                                    </div>
                                    <h3 className={styles.subTitle}>Pozostałe parametry umowy</h3>
                                    <div className={cx(styles.list)}>
                                        <ul>
                                            <li>
                                                <span>Jedorazowa kaucja zwrotna</span>
                                                <span>{`${formattedRentParams.net_administrative_fee} zł`}</span>
                                            </li>
                                            <li>
                        <span>
                          Łączna wartość brutto wynajmowanych produktów i
                          akcesoriów
                        </span>
                                                <span>{`${formattedRentParams.total_gross_price} zł`}</span>
                                            </li>
                                            {mainProduct && (
                                                <li className={styles.subItem}>
                                                    <span>{mainProduct.name}</span>
                                                    <span>{formatMoney(mainProduct.gross_price)} zł</span>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </>
                            )}

                            {!editMode && (
                                <>
                                    <div className={styles.emailSection}>
                                        <h3 className={styles.subTitle}>
                                            Pobierz ofertę na mail i wypełnij wniosek
                                        </h3>
                                        <p>
                                            Aby otrzymać szczegóły oferty o wybranych parametrach,
                                            uzupełnić uproszczony e-wniosek oraz zawrzeć umowę najmu,
                                            prosimy o podanie adresu e-mail:
                                        </p>
                                    </div>
                                    <div className={styles.nextContainer}>
                                        <div className={styles.inputContainer}>
                                            <TextInput
                                                name={"email"}
                                                type={"email"}
                                                onTextChange={onEmailChange}
                                                value={userEmail}
                                                error={error.message}
                                            />
                                        </div>
                                        <ReCaptcha
                                            ref={(ref) => (recaptcha = ref)}
                                            sitekey={CAPTCHA_SITE_KEY}
                                            action="submit"
                                            verifyCallback={verifyCallback}
                                        />
                                        <Button
                                            label={"Dalej"}
                                            onClick={sendEmail}
                                            loading={loading}
                                        />
                                    </div>
                                </>
                            )}
                            {editMode && (
                                <div className={styles.emailSection}>
                                    <div className={styles.nextContainer}>
                                        <div className={styles.inputContainer}>
                                            <Button
                                                label={"Zapisz"}
                                                onClick={updateData}
                                                loading={loading}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}

            {response && (
                <div className={styles.responseContainer}>
                    <CheckMark/>

                    {!!tempUrl && (
                        <p style={{textAlign: "center"}}>
                            Przejdź do swojego programu pocztowego i kliknij w otrzymany link.
                        </p>
                    )}
                </div>
            )}
        </div>
    );
});


const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
};

export default Calc;
