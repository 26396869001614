import React, { memo, useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/datepicker-overrides.css";
import pl from "date-fns/locale/pl";
import { useDispatch, useSelector } from "react-redux";
import { setFormItem } from "_redux/actions/form";
import { pathOr } from "ramda";
import Select from "react-select";
import styles from "_pages/styles/step-three.module.scss";

registerLocale("pl", pl);

const formatOptionLabel = ({ c1 }) => (
  <div className={styles.option}>
    <label>{c1}</label>
  </div>
);

const SelectInput = memo(({ options, name, onChange, value }) => {
  const dispatch = useDispatch();
  const [isInitial, setInitial] = useState(true);
  const formItems = useSelector((state) => state.form);

  const initialValue = pathOr(value, [name])(formItems);
  let currentValue = isInitial ? initialValue : value;

  if (name === "c40") {
    if (currentValue === true) {
      currentValue = { c1: "TAK", value: true };
    } else if (currentValue === false) {
      currentValue = { c1: "NIE", value: false };
    }
  }

  useEffect(() => {
    if (isInitial && initialValue) {
      onChange(currentValue);
      dispatch(setFormItem({ name, value: currentValue }));
    }
    // eslint-disable-next-line
  }, [isInitial, initialValue]);

  const onSelectChange = (val) => {
    setInitial(false);
    onChange(val);
    dispatch(setFormItem({ name, value: val }));
  };

  return (
    <div>
      <Select
        formatOptionLabel={formatOptionLabel}
        options={options}
        classNamePrefix={"sub-select"}
        isClearable={false}
        isSearchable={false}
        placeholder={"Wybierz"}
        onChange={onSelectChange}
        value={currentValue}
      />
    </div>
  );
});
SelectInput.displayName = "SelectInput";

SelectInput.defaultProps = {
  onChange: () => {},
  value: null,
  name: "name",
  options: [],
};

export default SelectInput;
