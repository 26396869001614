import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles/payment-method.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import TextInput from "_components/TextInput";
import cx from "classnames";
import { requestGetSummary, requestPayByCard } from "_api/transaction";
import { pathOr } from "ramda";
import CheckMark from "_components/CheckMark";
import CrossMark from "_components/CrossMark";
import { useHistory } from "react-router";
import Loader from "_components/Loader";

const PayByCard = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(null);
  const [summary, setSummary] = useState(null);

  const [requesting, setRequesting] = useState(false);
  const transaction_id = useSelector((state) => state.app.transaction_id);

  useEffect(() => {
    dispatch(setCurrentStep(5));
    loadSummary();
    // eslint-disable-next-line
  }, [dispatch]);

  const onTextChange = (prop) => (val) => {
    setErrors([]);
    setValues((prev) => ({ ...prev, [prop]: val }));
  };

  const hasError = (val) => {
    let res = null;
    errors.forEach((e) => {
      if (e.invalid_property === val) {
        res = e.message;
      }
    });

    return res;
  };

  const loadSummary = async () => {
    setLoading(true);
    try {
      const data = await requestGetSummary(transaction_id);
      setSummary(data);
    } catch (e) {}
    setLoading(false);
  };

  const payByCard = async (e) => {
    e.preventDefault();
    setRequesting(true);

    try {
      const data = await requestPayByCard(transaction_id, values);
      setStatus(data.payment_status);
      setErrors([]);
    } catch (e) {
      const errors = pathOr([], ["response", "data", "errors"])(e);
      setErrors(errors);
    }

    setRequesting(false);
  };

  const goBackFailed = () => {
    setStatus(null);
  };
  const goSuccessful = () => {
    history.push("/umowa-zawarta");
  };

  return (
    <div>
      {loading && <Loader />}
      {status === null && !loading && (
        <>
          <h3 className={styles.title}>Podanie danych karty kredytowej</h3>
          <div className={styles.content}>
            <div>
              <TextInput
                label={"Imię i nazwisko posiadacza karty"}
                name={"name_and_surname"}
                onTextChange={onTextChange("name_and_surname")}
                value={values.name_and_surname}
                error={hasError("name_and_surname")}
              />
              <TextInput
                label={"Numer karty kredytowej"}
                name={"credit_card_number"}
                onTextChange={onTextChange("credit_card_number")}
                value={values.credit_card_number}
                error={hasError("credit_card_number")}
              />
              <div className={cx(styles.inputGroup, styles.small)}>
                <TextInput
                  label={"Ważnosć karty"}
                  name={"card_valid_to"}
                  onTextChange={onTextChange("card_valid_to")}
                  error={hasError("card_valid_to")}
                  type={"date"}
                />
                <TextInput
                  label={"Numer CVC"}
                  name={"cvc"}
                  onTextChange={onTextChange("cvc")}
                  value={values.cvc}
                  error={hasError("cvc")}
                />
              </div>
            </div>
            {summary && (
              <div className={styles.summary}>
                <h3 className={styles.title}>Podsumowanie</h3>
                <div className={styles.list}>
                  <ul>
                    <li>
                      <span>Pierwsza rata wraz z kaucją</span>
                      <span className={styles.bold}>
                        {summary.first_payment} zł
                      </span>
                    </li>
                    <li>
                      <span>Miesięczna rata</span>
                      <span className={styles.bold}>
                        {summary.gross_month_cost} zł
                      </span>
                    </li>
                    <li>
                      <span>Ilość rat</span>
                      <span className={styles.bold}>{summary.months}</span>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <div className={styles.nextContainer}>
              <Button
                label={"Przejdź dalej"}
                onClick={payByCard}
                loading={requesting}
              />
            </div>
          </div>
        </>
      )}

      {status === "PAYMENT_SUCCESSFUL" && (
        <>
          <h3 className={styles.title}>
            Dziękujemy
            <br />
            Płatność została dokonana.
          </h3>

          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <CheckMark />
            </div>
            <div className={styles.nextContainer}>
              <Button label={"Przejdź dalej"} onClick={goSuccessful} />
            </div>
          </div>
        </>
      )}
      {status === "PAYMENT_FAILED" && (
        <>
          <h3 className={styles.title}>
            Przykro nam.
            <br />
            Twoja płatność nie została dokonana.
          </h3>

          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <CrossMark />
            </div>
            <div className={styles.nextContainer}>
              <Button label={"Wróć"} onClick={goBackFailed} />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default PayByCard;
