import React, { memo, useState } from "react";
import styles from "./styles/editable-text.module.scss";
import { formatMoney } from "utils/functions";

const EditableText = memo(
  ({ defaultValue, suffix, onChange, initialValue }) => {
    const [value, setValue] = useState(initialValue);
    const [editMode, setEditMode] = useState(false);
    let input = null;
    const setEdit = () => {
      setEditMode(true);
    };

    const onValueChange = (e) => {
      setValue(e.target.value);
    };

    const onInputBlur = () => {
      setEditMode(false);
      onChange(value);
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
        e.target.blur();
      }
    };

    return (
      <div className={styles.container} onClick={setEdit}>
        {!editMode && <span>{formatMoney(value)}</span>}
        {editMode && (
          <input
            ref={(ref) => (input = ref)}
            type="text"
            value={value}
            placeholder={defaultValue}
            onChange={onValueChange}
            onBlur={onInputBlur}
            autoFocus
            onKeyDown={handleKeyPress}
          />
        )}
        <span>{suffix}</span>
      </div>
    );
  }
);
EditableText.displayName = "EditableText";

EditableText.defaultProps = {
  suffix: " PLN",
  defaultValue: "10000",
  onChange: () => {},
  initialValue: "",
};

export default EditableText;
