import axios from "utils/axios";

export const requestSaveTransaction = async ({
  transaction_id,
  c2,
  c3,
  c5,
  c17,
  c29,
  c39,
  c45,
  formType,
}) => {
  const data = {
    c2,
    c3,
    c5,
    c17,
    c27: {
      c2: formType,
    },
    c39,
    c45,
    c29,
  };

  const urlType = formType.replace("_", "-").toLowerCase();

  return await axios
    .put(
      `/api/v1/transaction/save/step/2/form/1/${urlType}/${transaction_id}`,
      data
    )
    .then((res) => res.data);
};

export const requestSaveForm2 = async ({ transaction_id, data, formType }) => {
  const urlType = formType.replace("_", "-").toLowerCase();

  return await axios
    .put(
      `/api/v1/transaction/save/step/2/form/2/${urlType}/${transaction_id}`,
      data
    )
    .then((res) => res.data);
};

export const requestPutForm3 = async (transaction_id, data, formType) => {
  const urlType = formType.replace("_", "-").toLowerCase();
  let url = `/api/v1/transaction/save/step/2/form/consents/${urlType}/${transaction_id}`;
  if (formType === "LEGAL_PERSON") {
    url = `/api/v1/transaction/save/step/2/form/3/legal-person/${transaction_id}`;
  }
  return await axios.put(url, data).then((res) => res.data);
};

export const requestCheckStatus = async (token1, token2) => {
  return await axios
    .post(`/api/v1/init/transaction/step/3/check-status/${token1}/${token2}`)
    .then((res) => res.data);
};

export const requestSendSms = async (transaction_id) => {
  return await axios
    .post(`/api/v1/transaction/step/4/send-sms/${transaction_id}`, {})
    .then((res) => res.data);
};

export const requestVerifySmsCode = async (transaction_id, code) => {
  return await axios
    .post(`/api/v1/transaction/step/4/verify-sms-code/${transaction_id}`, {
      code,
    })
    .then((res) => res.data);
};

export const requestPayByCard = async (transaction_id, data) => {
  return await axios
    .post(`/api/v1/transaction/step/5/payment/imoje/${transaction_id}`, data)
    .then((res) => res.data);
};

export const requestGetSummary = async (transaction_id) => {
  return await axios
    .get(`/api/v1/transaction/step/5/summary/${transaction_id}`)
    .then((res) => res.data);
};

export const requestGetMaritalStatus = async () => {
  return await axios
    .get(`/api/v1/init/transaction/marital-status`)
    .then((res) => res.data);
};

export const requestGetHousingTenureStatus = async () => {
  return await axios
    .get(`/api/v1/init/transaction/housing-tenure-status`)
    .then((res) => res.data);
};

export const requestGetIncomeSource = async () => {
  return await axios
    .get(`/api/v1/init/transaction/income-source`)
    .then((res) => res.data);
};

export const requestPutFinancial = async (transaction_id, data) => {
  return await axios
    .put(
      `/api/v1/transaction/save/step/2/form/financial/${transaction_id}`,
      data
    )
    .then((res) => res.data);
};

export const requestUpdateCalc = async (
  transaction_id,
  { rentParams, mainProduct, accessory, servicesBefore, servicesDuring }
) => {
  let data = {
    rent_params: rentParams,
    products: {
      main_items: [{ ...mainProduct, quantity: 1 }],
      accessories_items: accessory && accessory.length > 0 ? accessory : [],
      services_before_items: servicesBefore
        ? [{ ...servicesBefore, quantity: 1 }]
        : [],
      services_during_items: servicesDuring
        ? [{ ...servicesDuring, quantity: 1 }]
        : [],
    },
  };
  return await axios
    .put(`/api/v1/transaction/step/1/${transaction_id}`, data)
    .then((res) => res.data);
};

export const requestGetAgreementPdf = async (transaction_id) => {
  return await axios
    .get(`/api/v1/transaction/agreement/html/${transaction_id}`)
    .then((res) => res.data);
};

export const requestLoginUserByTokens = async (token1, token2) => {
  return await axios
    .post(`/api/v1/init/transaction/step/5/login/${token1}/${token2}`, {})
    .then((res) => res.data);
};

export const requestLoginUserByTokensPayment = async (token1, token2) => {
  return await axios
      .post(`/api/v1/init/transaction/step/5/payment-login/${token1}/${token2}`, {})
      .then((res) => res.data);
};

export const requestGetData = async (transaction_id = null) => {
  return await axios
    .get(`/api/v1/transaction/save/step/2/my-data/${transaction_id}`)
    .then((res) => res.data);
};

export const requestRegisterPrzelewy24Transaction = async (transaction_id = null) => {
  return await axios
      .post(`/api/v1/transaction/step/5/przelewy24/register/${transaction_id}`)
      .then((res) => res.data);
};
export const requestRegisterPrzelewy24TransactionRecurring = async (transaction_id = null, schedule_id = 0) => {
  return await axios
      .post(`/api/v1/transaction/step/5/przelewy24/register-recurring/${transaction_id}/${schedule_id}`)
      .then((res) => res.data);
};

export const requestPrzelewy24Email = async (session_id = null) => {
  return await axios
      .post(`/api/v1/init/transaction/step/5/przelewy24/thank-you/${session_id}`)
      .then((res) => res.data);
};
