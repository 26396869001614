import React, {memo, useEffect, useState} from "react";
import { useDispatch } from "react-redux";

import styles from "./styles/agreement-successful.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import CheckMark from "_components/CheckMark";
import { requestPrzelewy24Email } from  "../_api/transaction";
import Loader from "_components/Loader";

const AgreementSuccessful = memo(({location, match}) => {
  const dispatch = useDispatch();
  const goto_shop = async () => {
      window.location.href = location.search.replace('?return_url=','');
  }

  const [loading,setLoading] = useState(true);

  useEffect(() => {
    dispatch(setCurrentStep(6));
  }, [dispatch]);

  useEffect(() => {
      let session_id = match.params.session_id;
       requestPrzelewy24Email(session_id)
           .then(()=>{
                setLoading(false);
            })


  },[match.params.session_id]);


  return (
      loading?(<div>
          <Loader />
      </div>) :(
        <div>
          <h3 className={styles.title}>
            Gratulacje!
            <br />
            Jeszcze tylko krok dzieli Cię od zawarcia umowy
          </h3>

          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <CheckMark />
            </div>
            <p>
                Na Twój adres email wysłaliśmy umowę do podpisu. <br/>
                Uprzejmie prosimy o wydrukowanie treści umowy, złożenie podpisu zgodnie z reprezentacją, zeskanowanie i upload skanu zgodnie z instrukcją zawartą w treści wiadomości przesłanej na wskazany adres e-mail, a następnie odesłanie dwóch podpisanych egzemplarzy kurierem lub pocztą na wskazany poniżej adres:
                Univibe Sp z o.o. ul. Sarmacka 1/19 02-972 Warszawa
            </p>
            <div className={styles.nextContainer}>
              <Button onClick={goto_shop} label={"Wróć do sklepu"} />
            </div>
          </div>
        </div>
        )
  );
});

export default AgreementSuccessful;
