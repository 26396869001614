import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { saveAs } from "file-saver";

import styles from "./styles/step-one.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import { requestConfirmEmail } from "_api/calculator";
import { pathOr } from "ramda";
import Loader from "_components/Loader";
import { API_URL } from "utils/constants";
import { Link } from "react-router-dom";

const StepOne = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token1, token2 } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    dispatch(setCurrentStep(1));
  }, [dispatch]);

  useEffect(() => {
    if (token1 && token2) {
      confirmEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token1, token2]);

  const nextStep = () => {
    history.push(`/dane-do-umowy/${data.transaction_id}`);
  };

  const confirmEmail = async () => {
    try {
      const response = await requestConfirmEmail({ token1, token2 });
      const data = pathOr(null, ["data"])(response);
      const uuid = await window.localStorage.getItem("uuid");
      await window.localStorage.setItem(`token_${uuid}`, data.token);
      setData(data);
    } catch (e) {}
  };

  const getTerms = (id) => async (e) => {
    e.preventDefault();
    try {
      await saveAs(
        `${API_URL}/api/v1/init/transaction/rent-conditions/${id}`,
        `oferta-najmu-${data.offer_number || id}.pdf`
      );
    } catch (e) {}
  };

  if (!data) {
    return <Loader />;
  }

  const {
    months,
    gross_month_cost,
    net_month_cost,
    net_administrative_fee,
  } = data.calculation.data_formatted;

  const monthLabel = (months) => {

    let m = parseInt(months)

    if(m === 1) {
      return "miesiąc"
    } else if(m === 3 || m === 24) {
      return "miesiące"
    } else if(m === 6 || m === 12) {
      return "miesięcy"
    } else {
      return ""
    }
  }

  return (
    <div>
      <h3 className={styles.title}>Parametry umowy</h3>
      <div className={styles.list}>
        <ul>
          <li>
            <span>Minimalny okres najmu</span>
            <span className={styles.bold}>
              {months} { monthLabel(months)}
            </span>
            <Link
              className={styles.changeBtn}
              to={`/kalkulator-cen-uslug/${data.shop_uuid}/${token1}/${token2}`}
            >
              zmień
            </Link>
          </li>
          <li>
            <span>Miesięczna rata najmu netto</span>
            <span className={styles.bold}>{net_month_cost} zł</span>
          </li>
          <li>
            <span>Miesięczna rata najmu brutto</span>
            <span className={styles.bold}>{gross_month_cost} zł</span>
          </li>
          <li>
            <span>Kaucja</span>
            <span className={styles.bold}>{net_administrative_fee} zł</span>
          </li>
          <li>
            <span>Punkt odbioru towaru</span>
            <span className={styles.bold}>{data.shop_name}</span>
          </li>
        </ul>
      </div>
      <div className={styles.nextContainer}>
        <Button
          label={"Pobierz Ofertę Najmu"}
          onClick={getTerms(data.transaction_id)}
          bordered
        />
        <Button label={"Przejdź dalej"} onClick={nextStep} />
      </div>
    </div>
  );
};

export default StepOne;
