import moment from "moment";
import { API_URL } from "utils/constants";
import { saveAs } from "file-saver";

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const parseTransactionId = (id) => {
  const formattedDate = moment().format("YYYY/MM/DD");
  return `${formattedDate}/${id.toString().padStart(8, "0")}`;
};

export const formatPrice = (num) => {
  return Math.round(num);
};

export const getLabelAgreementType = (name, type) => {
  switch (name) {
    case "c2":
      switch (type) {
        case "LEGAL_PERSON":
          return "Imię osoby reprezentującej Najemcę";
        case "INDIVIDUAL_ENTREPRENEUR":
          return "Imię przedsiębiorcy";
        case "NATURAL_PERSON":
          return "Imię";
        default:
          return "";
      }
    case "c3":
      switch (type) {
        case "LEGAL_PERSON":
          return "Nazwisko osoby reprezentującej Najemcę";
        case "INDIVIDUAL_ENTREPRENEUR":
          return "Nazwisko przedsiębiorcy";
        case "NATURAL_PERSON":
          return "Nazwisko";
        default:
          return "";
      }
    case "c5":
      switch (type) {
        case "LEGAL_PERSON":
          return "NIP Najemcy";
        case "INDIVIDUAL_ENTREPRENEUR":
          return "NIP";
        case "NATURAL_PERSON":
          return "NIP";
        default:
          return "";
      }
    case "c17":
      switch (type) {
        case "LEGAL_PERSON":
          return "Telefon komórkowy osoby reprezentującej Najemcę";
        case "INDIVIDUAL_ENTREPRENEUR":
          return "Telefon komórkowy";
        case "NATURAL_PERSON":
          return "Telefon komórkowy";
        default:
          return "";
      }
    default:
      return "";
  }
};

export const formatMoney = (number, decPlaces, decSep, thouSep) => {
  decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 0 : decPlaces;
  decSep = typeof decSep === "undefined" ? "" : decSep;
  thouSep = typeof thouSep === "undefined" ? " " : thouSep;

  const sign = number < 0 ? "-" : "";
  const i = String(
    parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
  );

  let result =
    sign +
    i.replace(/\B(?=(\d{3})+(?!\d))/g, thouSep) +
    (decPlaces
      ? decSep +
        Math.abs(number - i)
          .toFixed(decPlaces)
          .slice(2)
      : "");
  return result;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const uuidv4 = () => {
  // eslint-disable-next-line
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const downloadFile = async (url, name) => {
  const uuid = await window.localStorage.getItem("uuid");
  const token = await window.localStorage.getItem(`token_${uuid}`);

  fetch(`${API_URL}${url}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      let headers = res.headers;
      let content_disposition = headers.get('content-disposition') || false;
      if(!content_disposition){

      }else{
        name = decodeURI(content_disposition.split(';')[1].split('=')[1].replace("UTF-8''", ''));
      }
      return res.blob();
    })
    .then((blob) => {
      saveAs(blob, name);
    });
};
