export const API_URL = process.env.REACT_APP_API_URL;
export const DEV_MODE = process.env.REACT_APP_DEV_MODE === 'true';
export const CAPTCHA_SITE_KEY = process.env.REACT_APP_CAPTCHA_SITE_KEY;

export const steps = [
  {
    title: "Konfiguracja usługi",
    subtitle: "(1 min)",
    link: "/parametry-umowy",
  },
  {
    title: "Dane do umowy",
    subtitle: "(3 min)",
    link: "/dane-do-umowy",
  },
  {
    title: "Weryfikacja danych",
    subtitle: "(3 min)",
    link: "/weryfikacja-danych",
  },
  {
    title: "Akceptacja umowy",
    subtitle: "(8 min)",
  },
  {
    title: "Płatność",
    subtitle: "(1 min)",
  },
];
