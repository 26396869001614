import styles from "_pages/styles/step-three.module.scss";
import TextInput from "_components/TextInput";
import cx from "classnames";
import Button from "_components/Button";
import React from "react";
import { useSelector } from "react-redux";
import Back from "_components/Back";

const IndividualEntrepreneurData = ({
  onTextChange,
  hasError,
  values,
  nextStep,
  loading,
}) => {
  const form_params = useSelector((state) => state.app.form_params);
  return (
    <div>
      <h3 className={styles.title}>Dane Wnioskodawcy</h3>

      <div className={styles.inputsContainer}>
        <>
          <TextInput
            label={"Pełna nazwa prowadzonej działalności gospodarczej"}
            value={form_params.c1}
            name={"c1"}
            disabled
          />
          <div className={styles.inputGroup}>
            <TextInput
              label={"NIP"}
              value={form_params.c5}
              disabled
              name={"c5"}
            />
            <TextInput
              label={"REGON"}
              value={form_params.c6}
              disabled
              name={"c6"}
            />
          </div>
        </>

        <div className={styles.divider}>
          Adres wykonywania działalności gospodarczej
        </div>

        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Ulica i numer budynku"}
            value={form_params.c41}
            name={"c41"}
            disabled
          />
          <TextInput
            label={"Nr lokalu"}
            name={"c37"}
            onTextChange={onTextChange("c37")}
            value={form_params.c37}
            error={hasError("c37")}
          />
        </div>
        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Miasto"}
            value={form_params.c42}
            disabled
            name={"c42"}
          />
          <TextInput
            label={"Kod pocztowy"}
            value={form_params.c43}
            disabled
            name={"c43"}
          />
        </div>

        <div className={styles.divider}>Dane osobowe Wnioskodawcy</div>

        <TextInput
          label={"PESEL Wnioskodawcy"}
          name={"c4"}
          onTextChange={onTextChange("c4")}
          value={values.c4}
          error={hasError("c4")}
        />

        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Seria i numer dowodu"}
            name={"c8"}
            onTextChange={onTextChange("c8")}
            value={values.c8}
            error={hasError("c8")}
          />
          <TextInput
            label={"Data ważności dowodu"}
            name={"c32"}
            onTextChange={onTextChange("c32")}
            value={values.c32}
            error={hasError("c32")}
            type={"dateday"}
          />
        </div>

        <div className={styles.divider}>Adres zamieszkania Wnioskodawcy</div>

        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Ulica i numer budynku"}
            name={"c10"}
            onTextChange={onTextChange("c10")}
            value={values.c10}
            error={hasError("c10")}
          />
          <TextInput
            label={"Nr lokalu"}
            name={"c36"}
            onTextChange={onTextChange("c36")}
            value={values.c36}
            error={hasError("c36")}
          />
        </div>
        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Miasto"}
            name={"c11"}
            onTextChange={onTextChange("c11")}
            value={values.c11}
            error={hasError("c11")}
          />
          <TextInput
            label={"Kod pocztowy"}
            name={"c12"}
            onTextChange={onTextChange("c12")}
            value={values.c12}
            error={hasError("c12")}
          />
        </div>
      </div>
      <div className={styles.nextContainer}>
        <Back />
        <Button label={"Przejdź dalej"} onClick={nextStep} loading={loading} />
      </div>
    </div>
  );
};

export default IndividualEntrepreneurData;
