import React, { memo } from "react";
import styles from "./styles/crossmark.module.scss";


const CrossMark = memo(() => {
  return (
    <div className={styles.container}/>
  );
});
CrossMark.displayName = "CrossMark";

export default CrossMark;
