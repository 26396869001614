import React, {memo, Suspense, useEffect} from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import configureStore from "_redux/store";
import Layout from "_containers/Layout";
import Loader from "_components/Loader";
import StepTwo from "_pages/StepTwo";
import StepOne from "_pages/StepOne";
import StepThree from "_pages/StepThree";
import StepFour from "_pages/StepFour";
import Calc from "_pages/Calc";
import Agreement from "_pages/Agreement";
import AgreementAccept from "_pages/AgreementAccept";
import PaymentMethod from "_pages/PaymentMethod";
import PaymentMethodRepeat from "_pages/PaymentMethodRepeat";
import PayByCard from "_pages/PayByCard";
import AgreementSuccessful from "_pages/AgreementSuccessful";
import FinancialData from "_pages/FinancialData";
import Upload from "_pages/Upload";
import { uuidv4 } from "utils/functions";
import NoMatch from "_pages/NoMatch";
import UploadStatement, {UploadStatementLegalPerson} from "_pages/UploadStatement";
import PaymentSuccessful from "./_pages/PaymentSuccessful";
import jwt_decode from "jwt-decode";
import {useToasts} from "react-toast-notifications";


let { store, persistor } = configureStore();

const App = memo(() => {

  const { addToast } = useToasts();

  useEffect(() => {
    let  interval = null;
    let tokenDecoded = null;
    initUuidTokenIssues(interval,tokenDecoded);

    return () => {if(interval){clearInterval(interval);}}
// eslint-disable-next-line
  }, []);

  const generateUuid = async () => {
    const generated_uuidv4 = uuidv4();
    await window.localStorage.setItem("uuid", generated_uuidv4);
    return generated_uuidv4;
  };


  const fireTokenCheckInterval = (uuid,interval,tokenDecoded, timeout) => {
    interval = setInterval(async () => {


      if (tokenDecoded === null){
        const token = await window.localStorage.getItem(`token_${uuid}`);
        if(token){
          const decoded = jwt_decode(token);
          if(decoded?.exp){
            tokenDecoded = {...decoded};
          }
        }
      }

      if(tokenDecoded?.exp !== undefined){
        if(tokenDecoded.exp - Math.round(Date.now()/1000) < 0){
          clearInterval(interval);
          addToast("Wygasł token Twojej transakcji. Wejdź ponownie w otrzymany w mailu link.", {
            appearance: "error",
          });
        }else{
          if(tokenDecoded.exp - Math.round(Date.now()/1000) < 3600) {
            clearInterval(interval);
            fireTokenCheckInterval(uuid,interval,tokenDecoded,60000);
          }else{
            if(timeout === 1){
              clearInterval(interval);
              fireTokenCheckInterval(uuid,interval,tokenDecoded,1800000);
            }
          }

        }
      }
    },timeout);
  };

  const initUuidTokenIssues = async (interval,tokenDecoded) => {
    const generated_uuidv4 = await generateUuid();
    fireTokenCheckInterval(generated_uuidv4,interval,tokenDecoded,1);
  };

  return (
    <Suspense fallback="loading">
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Router>
            <Layout>
              <Switch>
                <Route exact path="/" component={NoMatch} />
                <Route exact path="/kalkulator-cen-uslug">
                  <Redirect to="/" />
                </Route>
                <Route
                  exact
                  path="/confirm-email/:token1/:token2"
                  render={(props) => (
                    <Redirect
                      to={`/parametry-umowy/${props.match.params.token1}/${props.match.params.token2}`}
                    />
                  )}
                />
                <Route
                  exact
                  path="/check-status/legal-person/:token1/:token2"
                  render={(props) => (
                    <Redirect
                      to={`/weryfikacja-danych/legal-person/${props.match.params.token1}/${props.match.params.token2}`}
                    />
                  )}
                />
                <Route
                  exact
                  path="/check-status/individual-entrepreneur/:token1/:token2"
                  render={(props) => (
                    <Redirect
                      to={`/weryfikacja-danych/individual-entrepreneur/${props.match.params.token1}/${props.match.params.token2}`}
                    />
                  )}
                />
                <Route
                  exact
                  path="/check-status/natural-person/:token1/:token2"
                  render={(props) => (
                    <Redirect
                      to={`/weryfikacja-danych/natural-person/${props.match.params.token1}/${props.match.params.token2}`}
                    />
                  )}
                />
                <Route
                  exact
                  path="/kalkulator-cen-uslug/:uuid/:token1?/:token2?"
                  component={Calc}
                />
                <Route
                  exact
                  path="/parametry-umowy/:token1/:token2"
                  component={StepOne}
                />
                <Route
                  exact
                  path="/dane-do-umowy/:transaction_id"
                  component={StepTwo}
                />
                <Route
                  exact
                  path="/dane-wnioskodawcy/:transaction_id"
                  component={StepThree}
                />
                <Route
                  exact
                  path="/dane-finansowe/:transaction_id"
                  component={FinancialData}
                />
                <Route
                  exact
                  path="/zaswiadczenie-o-zarobkach/:token1/:token2"
                  component={UploadStatement}
                />
                <Route
                    exact
                    path="/zaswiadczenie-osoba-prawna/:token1/:token2"
                    component={UploadStatementLegalPerson}
                />
                <Route
                  exact
                  path="/weryfikacja-danych/t/:transaction_id"
                  component={StepFour}
                />
                <Route
                  exact
                  path="/weryfikacja-danych/:type?/:token1?/:token2?"
                  component={StepFour}
                />
                <Route exact path="/umowa" component={Agreement} />
                <Route
                  exact
                  path="/akceptacja-umowy"
                  component={AgreementAccept}
                />
                <Route
                  exact
                  path="/metoda-platnosci/wybierz/:transaction_id"
                  component={PaymentMethod}
                />
                <Route
                  exact
                  path="/metoda-platnosci/ponowienie/:token1/:token2/:schedule_id"
                  component={PaymentMethodRepeat}
                />
                <Route
                  exact
                  path="/metoda-platnosci/platnosc-karta"
                  component={PayByCard}
                />
                <Route
                  exact
                  path="/umowa-zawarta/:session_id?"
                  component={AgreementSuccessful}
                />
                <Route
                    exact
                    path="/platnosc-zrealizowana/:session_id?"
                    component={PaymentSuccessful}
                />
                <Route
                  exact
                  path="/upload/:token1/:token2"
                  component={Upload}
                />
              </Switch>
            </Layout>
          </Router>
        </PersistGate>
      </Provider>
    </Suspense>
  );
});

export default App;
