import React, { memo, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./styles/payment-method.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import { requestRegisterPrzelewy24Transaction } from "../_api/transaction";
import { useParams } from "react-router-dom";
import { requestGetData, requestGetSummary } from "_api/transaction";
import Loader from "_components/Loader";

import visaMastercard from "images/visa-mastercard.png"
import masterpass from "images/masterpass.png";
import imoje from "images/imoje.png";

const PaymentMethod = memo(() => {
  const dispatch = useDispatch();
  const { transaction_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState(null);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    dispatch(setCurrentStep(5));
  }, [dispatch]);

  useEffect(() => {
    loadData(transaction_id);
  }, [transaction_id]);

  const loadData = async (id) => {
    setDataLoading(true);
    try {
      const data = await requestGetData(id);
      const summary = await requestGetSummary(id);
      setData(data);
      setSummary(summary);
    } catch (e) {}
    setDataLoading(false);
  };

  console.log(summary)

  const pay = async () => {
    setLoading(true);
    let link_data = await requestRegisterPrzelewy24Transaction(transaction_id);
    setLoading(false);
    window.location.href = link_data.link;
  };

  return (
    <div>

      {dataLoading && <Loader />}
      {!dataLoading && data && summary && (
        <>
          <div className={styles.header}>
            <h2>Podsumowanie zamówienia</h2>
            <p>Numer Umowy Najmu {summary.agreement_number}</p>
          </div>
          <div className={styles.data}>
            <div className={styles.dataElement}>
              <h3 className={styles.title}>Dane Klienta</h3>
              <p>
                <div>{`${data.c2} ${data.c3}`}</div>
                <div>{`${data.c12} ${data.c11}, ${data.c10}`}</div>
                <div>email: {data.c16}</div>
                <div>tel. {data.c17}</div>
              </p>
            </div>
            <div className={styles.dataElement}>
              <h3 className={styles.title}>Dane Sklepu</h3>
              <p>
                <div>Univibe Sp. z o.o.</div>
                <div>ul. Sarmacka 1</div>
                <div>02-972 Warszawa</div>
                <div>NIP 9512505831</div>
              </p>
            </div>
          </div>
          <h3 className={styles.title}>Zamówienie</h3>
          <div className={styles.list}>
            <ul>
              <li>
                <span>Miesięczna rata najmu brutto</span>
                <span className={styles.bold}>
                  {summary.gross_month_cost} zł
                </span>
              </li>
              <li>
                <span>Jednorazowa kaucja zwrotna</span>
                <span className={styles.bold}>{summary.net_administrative_fee} zł</span>
              </li>
              <li>
                <span>Pierwsza wpłata łącznie (kaucja + pierwsza rata)</span>
                <span className={styles.bold}>{summary.first_payment} zł</span>
              </li>
              <li>
                <span>Minimalna ilość miesięcznych rat najmu</span>
                <span className={styles.bold}>{summary.months}</span>
              </li>
              <li>
                <span>Punkt odbioru towaru</span>
                <span className={styles.bold}>{summary.shop_name}; ul. {summary.shop_street}; {summary.shop_code} {summary.shop_city}</span>
              </li>
              <li>
                <span>
                  Łączna wartość brutto wynajmowanych produktów i akcesoriów
                </span>
                <span className={styles.bold}>{summary.total_gross_price} zł</span>
              </li>
            </ul>
          </div>
        </>
      )}
      <h3 className={styles.title}>Forma płatności</h3>
      <div className={styles.content}>
        <div className={styles.buttons}>
          <div className={styles.group}>
            <button className={styles.active}>
              <img src={visaMastercard} alt={"visa-mastercard"} />
            </button>
            <button disabled>
              <img src={masterpass} alt={"masterpass"} />
            </button>
          </div>
          <button disabled>
            <img src={imoje} alt={"imoje"} />
          </button>
        </div>
        <div className={styles.nextContainer}>
          <Button label={"Płacę online"} onClick={pay} loading={loading} />
        </div>
      </div>
    </div>
  );
});

export default PaymentMethod;
