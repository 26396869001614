import React, {memo} from "react";

import styles from "./styles/agreement-successful.module.scss";
import CheckMark from "_components/CheckMark";


const PaymentSuccessful = memo(() => {

  return (
      (
        <div>
          <h3 className={styles.title}>
            Gratulacje!
            <br />
            Rata została opłacona
          </h3>

          <div className={styles.content}>
            <div className={styles.iconContainer}>
              <CheckMark />
            </div>
            <p>
                Na Twój adres email wysłaliśmy szczegóły płatności<br/>
            </p>

          </div>
        </div>
        )
  );
});

export default PaymentSuccessful;
