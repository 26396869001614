import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { useToasts } from "react-toast-notifications";

import styles from "./styles/step-three.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import { useHistory, useParams } from "react-router";
import TextInput from "_components/TextInput";
import { pathOr } from "ramda";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  requestGetHousingTenureStatus,
  requestGetIncomeSource,
  requestGetMaritalStatus,
  requestPutFinancial,
} from "_api/transaction";
import EditableText from "_components/EditableText";
import moment from "moment";

import pl from "date-fns/locale/pl";
import SelectInput from "_components/SelectInput";
import { setFormItem } from "_redux/actions/form";
import Back from "_components/Back";

registerLocale("pl", pl);

const FinancialData = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const formType = useSelector((state) => state.app.formType);

  const [martialStatuses, setMartialStatuses] = useState([]);
  const [housingStatuses, setHousingStatuses] = useState([]);
  const [incomeSources, setIncomeSources] = useState([]);
  const [martialStatus, setMartialStatus] = useState(null);
  const [housingStatus, setHousingStatus] = useState(null);
  const [incomeSource, setIncomeSource] = useState(null);

  const [cardLiabilities, setCardLiabilities] = useState([]);
  const [creditLiabilities, setCreditLiabilities] = useState([]);

  const [propertySeparation, setPropertySeparation] = useState({
    c1: "NIE",
    value: false,
  });

  const { transaction_id } = useParams();
  const formItems = useSelector((state) => state.form);

  useEffect(() => {
    dispatch(setFormItem({ name: "cardLiabilities", value: cardLiabilities }));
    // eslint-disable-next-line
  }, [cardLiabilities]);

  useEffect(() => {
    dispatch(
      setFormItem({ name: "creditLiabilities", value: creditLiabilities })
    );
    // eslint-disable-next-line
  }, [creditLiabilities]);

  useEffect(() => {
    dispatch(setCurrentStep(2));
    loadData();
    const storedCardLiabilities = pathOr(null, ["cardLiabilities"])(formItems);
    const storedCreditLiabilities = pathOr(null, ["creditLiabilities"])(
      formItems
    );

    if (storedCardLiabilities) {
      setCardLiabilities(storedCardLiabilities);
    } else {
      addCardLiability();
    }
    if (storedCreditLiabilities) {
      setCreditLiabilities(storedCreditLiabilities);
    } else {
      addCreditLiability();
    }
    // eslint-disable-next-line
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const martialResponse = await requestGetMaritalStatus();
      const martialData = pathOr([], ["data"])(martialResponse);
      setMartialStatuses(martialData);

      const housingResponse = await requestGetHousingTenureStatus();
      const housingData = pathOr([], ["data"])(housingResponse);
      setHousingStatuses(housingData);

      const incomeResponse = await requestGetIncomeSource();
      const incomeData = pathOr([], ["data"])(incomeResponse);
      setIncomeSources(incomeData);
    } catch (e) {}
    setLoading(false);
  };

  const nextStep = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let data = {
        c44: housingStatus,
        c31: martialStatus,
        c40: propertySeparation && !!propertySeparation.value ? 1 : 0,
        credit_card_liabilities: cardLiabilities
          .filter((el) => !!el.c1)
          .map((el) => ({
            ...el,
            c3: moment(el.c3).format("YYYY-MM-DD"),
            c2: !!el.c2 ? el.c2 : 0,
          })),
        standard_liabilities: creditLiabilities
          .filter((el) => !!el.c1)
          .map((el) => ({
            ...el,
            c3: moment(el.c3).format("YYYY-MM-DD"),
            c2: !!el.c2 ? el.c2 : 0,
          })),
        ...values,
      };
      if (formType === "NATURAL_PERSON" && incomeSource) {
        data = { ...data, c30: incomeSource };
      }

      await requestPutFinancial(transaction_id, data);
      history.push(`/weryfikacja-danych/t/${transaction_id}`);
    } catch (e) {
      const errors = pathOr([], ["response", "data", "errors"])(e);
      const message = pathOr(null, ["response", "data", "data"])(e);
      setErrors(errors);
      if (message) {
        addToast(message, {
          appearance: "error",
        });
      }
    }
    setLoading(false);
  };

  const onTextChange = (prop) => (val) => {
    setErrors([]);
    setValues((prev) => ({ ...prev, [prop]: val }));
  };

  const hasError = (val) => {
    let res = null;
    errors.forEach((e) => {
      if (e.invalid_property === val) {
        res = e.message;
      }
    });

    return res;
  };

  const addCardLiability = () => {
    setCardLiabilities((prev) => [
      ...prev,
      { c3: new Date(moment().add(1, "month")) },
    ]);
  };

  const removeCardLiability = (index) => (e) => {
    setCardLiabilities((prev) => prev.filter((el, i) => i !== index));
  };

  const addCreditLiability = () => {
    setCreditLiabilities((prev) => [
      ...prev,
      { c3: new Date(moment().add(1, "year")) },
    ]);
  };

  const removeCreditLiability = (index) => (e) => {
    setCreditLiabilities((prev) => prev.filter((el, i) => i !== index));
  };

  const changeArrValue = (prev, index, name, value) => {
    return prev.map((el, i) => {
      if (i === index) {
        el[name] = value;
      }
      return el;
    });
  };

  const changeCardValue = (index, name) => (value) => {
    setCardLiabilities((prev) => changeArrValue(prev, index, name, value));
  };

  const changeCreditValue = (index, name) => (value) => {
    setCreditLiabilities((prev) => changeArrValue(prev, index, name, value));
  };

  const CustomDateInput = ({ value, onClick }) => (
    <span onClick={onClick}>{value}</span>
  );

  const c44Error = hasError("c44");
  const c31Error = hasError("c31");
  const c40Error = hasError("c40");
  const c30Error = hasError("c30");

  const shouldSelect40 = !!(
    martialStatus && martialStatus.c2.indexOf("MARRIED") > -1
  );
  const c24Married =
    shouldSelect40 && propertySeparation && propertySeparation.value === false;

  return (
    <div>
      <h3 className={styles.title}>Dane finansowe</h3>
      <div className={styles.inputsContainer}>
        <div
          className={cx(styles.inputGroup, styles.selectGroup, {
            [styles.withC40]: shouldSelect40,
          })}
        >
          <div className={cx(styles.select, { [styles.errored]: !!c44Error })}>
            <div className={styles.selectLabel}>
              <span>Status mieszkaniowy</span>
            </div>
            <SelectInput
              value={housingStatus}
              options={housingStatuses.map((s) => ({ ...s, value: s }))}
              onChange={setHousingStatus}
              name={"c44"}
            />
            <span className={styles.error}>{c44Error}</span>
          </div>
          <div className={cx(styles.select, { [styles.errored]: !!c31Error })}>
            <div className={styles.selectLabel}>
              <span>Stan cywilny</span>
            </div>
            <SelectInput
              value={martialStatus}
              options={martialStatuses.map((s) => ({ ...s, value: s }))}
              onChange={setMartialStatus}
              name={"c31"}
            />
            <span className={styles.error}>{c31Error}</span>
          </div>
          {shouldSelect40 && (
            <div
              className={cx(styles.select, { [styles.errored]: !!c40Error })}
            >
              <div className={styles.selectLabel}>
                <span>Rozdzielność majątkowa</span>
                <span className={styles.error}>{c40Error}</span>
              </div>
              <SelectInput
                value={propertySeparation}
                options={[
                  { c1: "TAK", value: true },
                  { c1: "NIE", value: false },
                ]}
                onChange={setPropertySeparation}
                name={"c40"}
              />
            </div>
          )}
        </div>
        {shouldSelect40 && propertySeparation && !propertySeparation.value && (
          <div className={styles.separationGroup}>
            <div className={styles.inputGroup}>
              <TextInput
                label={"Imię Współmałżonka"}
                value={values.c50}
                name={"c50"}
                onTextChange={onTextChange("c50")}
                error={hasError("c50")}
              />
              <TextInput
                label={"Nazwisko Współmałżonka"}
                value={values.c51}
                name={"c51"}
                onTextChange={onTextChange("c51")}
                error={hasError("c51")}
              />
              <TextInput
                label={"PESEL Współmałżonka"}
                value={values.c52}
                name={"c52"}
                onTextChange={onTextChange("c52")}
                error={hasError("c52")}
              />
            </div>
          </div>
        )}

        <div className={styles.divider}>Dochody miesięczne</div>
        <div className={styles.inputGroup}>
          <TextInput
            label={
              c24Married
                ? "Łączny dochód miesieczny netto Wnioskodawcy oraz Współmałżonka"
                : "Dochód miesięczny netto Wnioskodawcy"
            }
            value={values.c24}
            name={"c24"}
            onTextChange={onTextChange("c24")}
            error={hasError("c24")}
          />
        </div>

        {formType === "NATURAL_PERSON" && (
          <div className={cx(styles.inputGroup, styles.selectGroup)}>
            <div
              className={cx(styles.select, styles.selectFull, {
                [styles.errored]: !!c30Error,
              })}
            >
              <div className={styles.selectLabel}>
                <span>Źródło dochodów</span>
                <span className={styles.error}>{c30Error}</span>
              </div>
              <SelectInput
                value={incomeSource}
                options={incomeSources.map((s) => ({ ...s, value: s }))}
                onChange={setIncomeSource}
                name={"c30"}
              />
            </div>
          </div>
        )}
        <div className={styles.divider}>Wydatki miesięczne</div>
        <div className={styles.inputGroup}>
          <TextInput
            label={"Liczba osób na utrzymaniu"}
            value={values.c38}
            name={"c38"}
            onTextChange={onTextChange("c38")}
            error={hasError("c38")}
          />
        </div>
        <div className={styles.liabilities}>
          {cardLiabilities.length > 0 && (
            <label>Zobowiazania kart kredytowych</label>
          )}
          <ul>
            {cardLiabilities.length > 0 && (
              <li>
                <span>Przyznany limit</span>
                <span>Wykorzystano</span>
                <span>Wymagany termin spłaty</span>
              </li>
            )}
            {cardLiabilities.map((item, index) => (
              <li key={index}>
                <EditableText
                  onChange={changeCardValue(index, "c1")}
                  initialValue={item.c1}
                />
                <EditableText
                  onChange={changeCardValue(index, "c2")}
                  initialValue={item.c2}
                />
                <div className={styles.dateWrapper}>
                  <DatePicker
                    locale="pl"
                    selected={item.c3}
                    onChange={changeCardValue(index, "c3")}
                    dateFormat="dd-MM-yyyy"
                    showYearDropdown
                    scrollableYearDropdown={true}
                    minDate={new Date()}
                    customInput={<CustomDateInput />}
                  />
                  <div className={styles.removeRowWrapper}>
                    <span
                      className={styles.removeRow}
                      onClick={removeCardLiability(index)}
                    >
                      usuń
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.add} onClick={addCardLiability}>
            <em />
            Dodaj zobowiązanie z karty kredytowej
          </div>
        </div>
        <div className={styles.liabilities}>
          {creditLiabilities.length > 0 && (
            <label>Zobowiazania kredytowe, pożyczkowe i leasingowe</label>
          )}
          <ul>
            {creditLiabilities.length > 0 && (
              <li>
                <span>Przyznany limit</span>
                <span>Ile spłacono</span>
                <span>Data ostatniej raty</span>
              </li>
            )}
            {creditLiabilities.map((item, index) => (
              <li key={index}>
                <EditableText
                  onChange={changeCreditValue(index, "c1")}
                  initialValue={item.c1}
                />
                <EditableText
                  onChange={changeCreditValue(index, "c2")}
                  initialValue={item.c2}
                  value={0}
                />
                <div className={styles.dateWrapper}>
                  <DatePicker
                    locale="pl"
                    selected={item.c3}
                    onChange={changeCreditValue(index, "c3")}
                    showYearDropdown
                    scrollableYearDropdown={true}
                    dateFormat="dd-MM-yyyy"
                    minDate={new Date()}
                    customInput={<CustomDateInput />}
                  />
                  <div className={styles.removeRowWrapper}>
                    <span
                      className={styles.removeRow}
                      onClick={removeCreditLiability(index)}
                    >
                      usuń
                    </span>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.add} onClick={addCreditLiability}>
            <em />
            Dodaj zobowiązanie kredytowe, pożyczkowe lub leasingowe
          </div>
        </div>
      </div>
      <div className={styles.nextContainer}>
        <Back />
        <Button label={"Przejdź dalej"} onClick={nextStep} loading={loading} />
      </div>
    </div>
  );
});

export default FinancialData;
