import { combineReducers } from "redux";
import app from "_redux/reducers/app";
import form from "_redux/reducers/form";

const rootReducer = combineReducers({
  app,
  form,
});

export default rootReducer;
