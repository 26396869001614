import axios from "axios";
import { pathOr } from "ramda";
import { API_URL } from "utils/constants";
export const baseURL = API_URL;

const client = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(async (value) => {
  const uuid = await window.localStorage.getItem("uuid");
  const token = await window.localStorage.getItem(`token_${uuid}`);
  if (token) {
    value.headers.Authorization = `Bearer ${token}`;
  }

  return value;
});

client.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const status = pathOr(null, ["response", ["status"]])(error);
    if (status === 401) {
        const uuid = await window.localStorage.getItem("uuid");
      await window.localStorage.removeItem(`token_${uuid}`);
      await window.localStorage.removeItem(`uuid`);
      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default client;
