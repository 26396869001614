import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";

import styles from "./styles/step-four.module.scss";
import Button from "_components/Button";
import {
  setCurrentStep,
  setFormType,
  setTransactionData,
} from "_redux/actions/app";
import CheckMark from "_components/CheckMark";
import {
  requestCheckStatus,
  requestGetData,
  requestPutForm3,
} from "_api/transaction";
import Loader from "_components/Loader";
import { useHistory, useParams } from "react-router";
import { DEV_MODE } from "utils/constants";
import Checkbox from "_components/Checkbox";
import { setFormItems } from "_redux/actions/form";
import Back from "_components/Back";

const StepFour = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token1, token2, transaction_id, type } = useParams();
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);

  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [statusLoaded, setStatusLoaded] = useState(false);

  const formType = useSelector((state) => state.app.formType);
  const transactionId = transaction_id || token2;

  useEffect(() => {
    dispatch(setCurrentStep(3));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (statusLoaded && status === 'verified') {
      getData(transactionId);
    }
    // eslint-disable-next-line
  }, [statusLoaded]);

  useEffect(() => {
    if (token1 && token2) {
      checkStatus();
    }
    // eslint-disable-next-line
  }, [token1, token2]);

  useEffect(() => {
    if (type) {
      dispatch(setFormType(type.replace("-", "_").toUpperCase()));
    }
    // eslint-disable-next-line
  }, [type]);

  const getData = async (id) => {
    if (id) {
      try {
        const data = await requestGetData(id);
        dispatch(setFormItems(data));
      } catch (e) {}
    }
  };

  const next = async () => {
    setLoading(true);
    try {
      let consents = [
        {
          consent_id: 1,
          checked: check1,
        },
        {
          consent_id: 2,
          checked: check2,
        },
      ];

      if (formType === "LEGAL_PERSON") {
        consents = [
          {
            consent_id: 3,
            checked: check3,
          },
        ];
      } else if (formType === "NATURAL_PERSON") {
        consents = [
          {
            consent_id: 4,
            checked: check4,
          },
        ];
      }

      const data = await requestPutForm3(transactionId, { consents }, formType);
      history.push(data.data);

      setStatus("waiting");
    } catch (e) {}

    setLoading(false);
  };

  const checkStatus = async () => {
    setLoading(true);
    try {
      const data = await requestCheckStatus(token1, token2);
      setStatusLoaded(true);
      dispatch(setTransactionData(data));
      if (data.status === "WAITING_FOR_RESERVATION" || data.status === "WAITING_FOR_DECISION" || data.status === "BIK_ERROR") {

        if (DEV_MODE) {
          setStatus("verified");
        } else {
          setStatus("waiting");
        }
      } else if (data.status === "REJECTED") {
        setStatus("rejected");
      } else if (data.status === "WAITING_FOR_AGREEMENT") {
        setStatus("verified");
      } else {
        setStatus("expired");
      }
    } catch (e) {}
    setLoading(false);
  };

  const goToAgreement = () => {
    history.push(`/umowa`);
  };

  return (
    <div>
      {!loading && status === null && (
        <h3 className={styles.title}>
          Zaakceptuj warunki najmu i złóż wniosek
        </h3>
      )}
      {status === "expired" && (
          <h3 className={styles.title}>
            Ten link
            <br />
            wygasł
          </h3>
      )}
      {status === "waiting" && (
        <h3 className={styles.title}>
          Twoj wniosek
          <br />
          czeka na weryfikację
        </h3>
      )}
      {status === "rejected" && (
        <h3 className={styles.title}>
          Twoj wniosek
          <br />
          został odrzucony
        </h3>
      )}
      {status === "verified" && (
        <h3 className={styles.title}>
          Twoj wniosek
          <br />
          otrzymał akceptację
        </h3>
      )}
      {loading && <Loader />}
      {!loading && (
        <>
          <div className={styles.content}>
            {status === null && (
              <>
                {formType === "INDIVIDUAL_ENTREPRENEUR" && (
                  <>
                    <Checkbox
                      label={
                        "*Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu upoważnienia do pozyskania z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 dotyczących mnie informacji gospodarczych oraz do pozyskania za pośrednictwem BIG InfoMonitor S.A. danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w tym między innymi oceny punktowej (scoring), w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze. zm.) oraz na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku - Prawo bankowe (tj. Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych, wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na mój temat do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy.."
                      }
                      showAll
                      onChange={setCheck1}
                      checked={check1}
                    />
                    <Checkbox
                      label={
                        "*Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu jako przedsiębiorcy prowadzącego działalność gospodarczą, upoważnienia do pozyskania za pośrednictwem Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 danych gospodarczych związanych z prowadzoną przeze mnie działalnością gospodarczą z Biura Informacji Kredytowej S.A. i Związku Banków Polskich w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku - Prawo bankowe (tekst jednolity Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze zm.), wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na temat reprezentowanego przez nas podmiotu do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy."
                      }
                      showAll
                      onChange={setCheck2}
                      checked={check2}
                    />
                  </>
                )}
                {formType === "LEGAL_PERSON" && (
                  <>
                    <Checkbox
                      label={
                        "*Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w imieniu reprezentowanego przeze mnie podmiotu upoważnienia do pozyskania za pośrednictwem Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku – Prawo bankowe (tekst jednolity Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze zm.), wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na temat reprezentowanego przez nas podmiotu do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy."
                      }
                      showAll
                      onChange={setCheck3}
                      checked={check3}
                    />
                  </>
                )}
                {formType === "NATURAL_PERSON" && (
                  <>
                    <Checkbox
                      label={
                        "*Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu upoważnienia do pozyskania z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 dotyczących mnie informacji gospodarczych oraz do pozyskania za pośrednictwem BIG InfoMonitor S.A. danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w tym między innymi oceny punktowej (scoring), w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze. Zm.) oraz na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku – Prawo bankowe (tj. Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych, wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na mój temat do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy."
                      }
                      showAll
                      onChange={setCheck4}
                      checked={check4}
                    />
                  </>
                )}
              </>
            )}

            {status === "waiting" && (
              <div className={styles.waitingContainer}>
                <CheckMark />
                <p>
                  Na podany adres email wysłaliśmy wiadomość zawierającą link do
                  aktualnego statusu wniosku. Poinformujemy Cię mailowo gdy Twój
                  wniosek zmieni status.
                </p>
              </div>
            )}

            {status === "verified" && (
              <div className={styles.waitingContainer}>
                <CheckMark />
              </div>
            )}
          </div>

          {status === null && (
            <div className={styles.nextContainer}>
              <Back />
              <Button
                label={"Złóż wniosek"}
                onClick={next}
                disabled={
                  formType === "INDIVIDUAL_ENTREPRENEUR"
                    ? !(check1 && check2)
                    : formType === "LEGAL_PERSON"
                    ? !check3
                    : !check4
                }
              />
            </div>
          )}
          {status === "verified" && (
            <div className={cx(styles.nextContainer, styles.onlyNext)}>
              <Button label={"Przejdź dalej"} onClick={goToAgreement} />
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default StepFour;
