import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { setCurrentStep } from "_redux/actions/app";
import { useHistory, useParams } from "react-router";
import { requestGetData, requestSaveForm2 } from "_api/transaction";
import { pathOr } from "ramda";
import NaturalPersonData from "_components/NaturalPersonData";
import LegalPersonData from "_components/LegalPersonData";
import IndividualEntrepreneurData from "_components/IndividualEntrepreneurData";
import { setFormItems } from "_redux/actions/form";

const StepThree = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const { transaction_id } = useParams();

  const form_params = useSelector((state) => state.app.form_params);
  const formType = useSelector((state) => state.app.formType);

  useEffect(() => {
    dispatch(setCurrentStep(2));
    getData(transaction_id);
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (form_params.c4) {
      setValues((prev) => ({ ...prev, c4: form_params.c4 }));
    }
    if (form_params.c37) {
      setValues((prev) => ({ ...prev, c37: form_params.c37 }));
    }

  }, [form_params]);

  const nextStep = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await requestSaveForm2({
        transaction_id,
        data: { ...form_params, ...values },
        formType,
      });
      setErrors([]);
      if (
        formType === "INDIVIDUAL_ENTREPRENEUR" ||
        formType === "NATURAL_PERSON"
      ) {
        history.push(`/dane-finansowe/${transaction_id}`);
      } else {
        history.push(`/weryfikacja-danych/t/${transaction_id}`);
      }
    } catch (e) {
      const errors = pathOr([], ["response", "data", "errors"])(e);
      const message = pathOr(null, ["response", "data", "data"])(e);
      setErrors(errors);
      if (message) {
        addToast(message, {
          appearance: "error",
        });
      }
    }
    setLoading(false);
  };

  const onTextChange = (prop) => (val) => {
    setErrors([]);
    if(prop ===  'c8'){
      val = val.replace(/\s/g, "");
    }
    setValues((prev) => ({ ...prev, [prop]: val }));
  };

  const hasError = (val) => {
    let res = null;
    errors.forEach((e) => {
      if (e.invalid_property === val) {
        res = e.message;
      }
    });

    return res;
  };

  const getData = async (id) => {
    if (id) {
      try {
        const data = await requestGetData(id);
        dispatch(setFormItems(data));
      } catch (e) {}
    }
  };

  if (formType === "NATURAL_PERSON") {
    return (
      <NaturalPersonData
        onTextChange={onTextChange}
        hasError={hasError}
        values={values}
        nextStep={nextStep}
        loading={loading}
      />
    );
  } else if (formType === "LEGAL_PERSON") {
    return (
      <LegalPersonData
        onTextChange={onTextChange}
        hasError={hasError}
        values={values}
        nextStep={nextStep}
        loading={loading}
      />
    );
  } else if (formType === "INDIVIDUAL_ENTREPRENEUR") {
    return (
      <IndividualEntrepreneurData
        onTextChange={onTextChange}
        hasError={hasError}
        values={values}
        nextStep={nextStep}
        loading={loading}
      />
    );
  }

  return null;
});

export default StepThree;
