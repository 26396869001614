import React, { memo } from "react";
import styles from "_containers/styles/layout.module.scss";
import Navigator from "_containers/Navigator";
import { useSelector } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

const logo = require("../images/logo.svg");

const Layout = memo(({ children }) => {
  const offer_number = useSelector((state) => state.app.offer_number);

  return (
    <ToastProvider autoDismiss autoDismissTimeout={4000} placement="top-center">
      <div className={styles.container}>
        <div className={styles.content}>
          <section className={styles.formWrapper}>
            <header>
              <h1>
                <img src={logo} alt={"g4rent"} className={styles.logo} />
              </h1>
              <h2>Wynajmij sprzęt online</h2>
            </header>
            <div className={styles.childContainer}>{children}</div>
          </section>
          <footer>
            <ul>
              <li>
                <span className={styles.header}>Potrzebujesz pomocy?</span>
              </li>
              {offer_number && (
                <li>
                  <span>Numer wniosku: {offer_number}</span>
                </li>
              )}
              <li>e-mail: info@g4.rent</li>
              <li>tel. 510 510 092</li>
            </ul>
            <p className={styles.address}>
              Univibe Sp. z o.o. ul. Sarmacka 1, 02-972 Warszawa. NIP:
              9512505831, KRS: 0000853684
              <br />
              <a
                href={
                  "https://g4.rent/wp-content/uploads/2021/01/Regulamin-swiadczenia-uslug-UniVibe-droga-elektroniczna.pdf"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                Regulamin sklepu
              </a>
            </p>
          </footer>
        </div>
        <Navigator />
      </div>
    </ToastProvider>
  );
});

export default Layout;
