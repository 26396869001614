import React, { memo, useEffect, useState } from "react";
import styles from "./styles/step-two.module.scss";
import RadioButton from "_components/RadioButton";
import TextInput from "_components/TextInput";
import Checkbox from "_components/Checkbox";
import Button from "_components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentStep, setParams } from "_redux/actions/app";
import { useHistory, useParams } from "react-router-dom";
import { requestGetData, requestSaveTransaction } from "_api/transaction";
import { pathOr } from "ramda";
import { getLabelAgreementType } from "utils/functions";
import { useToasts } from "react-toast-notifications";
import Back from "_components/Back";
import { setFormItems } from "_redux/actions/form";

const StepTwo = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { transaction_id } = useParams();
  const { addToast } = useToasts();

  const [values, setValues] = useState({});
  const [errors, setErrors] = useState([]);


  const [checkboxes, setCheckboxes] = useState({
    0: false,
    1: false,
    2: false,
  });
  const formType = useSelector((state) => state.app.formType);

  useEffect(() => {
    dispatch(setCurrentStep(2));
    getData(transaction_id);
    // eslint-disable-next-line
  }, [dispatch]);

  const nextStep = async (e) => {
    e.preventDefault();

    try {
      const data = await requestSaveTransaction({
        transaction_id,
        ...values,
        formType,
        c39: !!checkboxes["0"] ? "1" : "0",
        c45: !!checkboxes["1"] ? "1" : "0",
        c29: !!checkboxes["2"] ? "1" : "0",
      });

      const response = pathOr(null, ["data"])(data);
      dispatch(setParams(response));
      history.push(`/dane-wnioskodawcy/${transaction_id}`);
    } catch (e) {
      const errors = pathOr([], ["response", "data", "errors"])(e);
      setErrors(errors);
      const message = pathOr(null, ["response", "data", "data"])(e);

      if (message) {
        addToast(message, {
          appearance: "error",
        });
      }
    }
  };
  const getData = async (id) => {
    if (id) {
      try {
        const data = await requestGetData(id);
        dispatch(setFormItems(data));
      } catch (e) {}
    }
  };

  const onTextChange = (prop) => (val) => {
    setErrors([]);
    setValues((prev) => ({ ...prev, [prop]: val }));
  };

  const hasError = (val) => {
    let res = null;
    errors.forEach((e) => {
      if (e.invalid_property === val) {
        res = e.message;
      }
    });

    return res;
  };

  const setCheckbox = (num) => (val) => {
    setCheckboxes((prev) => ({ ...prev, [num]: val }));
  };

  return (
    <div>
      <h3 className={styles.title}>Wybierz wariant umowy</h3>
      <div>
        <div className={styles.typeContainer}>
          <RadioButton label={"Osoba fizyczna"} type={"NATURAL_PERSON"} />
          <RadioButton
            label={"Działalność gospodarcza"}
            type={"INDIVIDUAL_ENTREPRENEUR"}
          />
          <RadioButton label={"Spółka lub inna osoba prawna"} type={"LEGAL_PERSON"} />
        </div>
        <div className={styles.inputsContainer}>
          <TextInput
            label={getLabelAgreementType("c2", formType)}
            placeholder={"Jan"}
            name={"c2"}
            onTextChange={onTextChange("c2")}
            value={values.c2}
            error={hasError("c2")}
          />
          <TextInput
            label={getLabelAgreementType("c3", formType)}
            placeholder={"Kowalski"}
            name={"c3"}
            onTextChange={onTextChange("c3")}
            value={values.c3}
            error={hasError("c3")}
          />
          <TextInput
            label={getLabelAgreementType("c5", formType)}
            placeholder={"1234567890"}
            name={"c5"}
            value={values.c5}
            onTextChange={onTextChange("c5")}
            error={hasError("c5")}
            optional={formType === "NATURAL_PERSON"}
          />
          <TextInput
            label={getLabelAgreementType("c17", formType)}
            placeholder={"+48 111 222 333"}
            name={"c17"}
            onTextChange={onTextChange("c17")}
            value={values.c17}
            error={hasError("c17")}
          />
        </div>
        <div className={styles.checkboxContainer}>
          <Checkbox
            htmlLabel={
              "Wyrażam zgodę na przetwarzanie przez Univibe Sp. z o.o. (ul. Sarmacka 1/19, 02-972 Warszawa), moich danych osobowych w postaci adresu email w celu przesyłania mi za pomocą środków komunikacji elektronicznej informacji handlowych dotyczących produktów i usług oferowanych przez tę Spółkę. <br/><br/>Informujemy, że zgoda może zostać cofnięta w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania danych osobowych, które nastąpiło przed cofnięciem zgody. Cofnięcie zgody może nastąpić przez wysłanie wiadomości na nasz adres e-mail: info@g4.rent"
            }
            checked={checkboxes["0"]}
            errored={hasError("c39")}
            onChange={setCheckbox(0)}
          />
          <Checkbox
            htmlLabel={
              "Wyrażam zgodę na przetwarzanie przez Univibe Sp. z o.o. (ul. Sarmacka 1/19, 02-972 Warszawa) moich danych osobowych w postaci numeru telefonu w celu marketingu bezpośredniego produktów i usług oferowanych przez tę Spółkę z wykorzystaniem takich urządzeń jak telefony, smartfony oraz automatyczne systemy wywołujące.<br/><br/>" +
              "Informujemy, że zgoda może zostać cofnięta w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania danych osobowych, które nastąpiło przed cofnięciem zgody. Cofnięcie zgody może nastąpić przez wysłanie wiadomości na nasz adres e-mail: info@g4.rent"
            }
            checked={checkboxes["1"]}
            errored={hasError("c45")}
            onChange={setCheckbox(1)}
          />
          <Checkbox
            htmlLabel={
              '* Akceptuję <a href="https://g4.rent/wp-content/uploads/2021/01/Polityka-Prywatnos%CC%81ci-UniVibe.pdf" target="_blank">politykę prywatności</a>  oraz <a href="https://g4.rent/wp-content/uploads/2021/01/Regulamin-swiadczenia-uslug-UniVibe-droga-elektroniczna.pdf"  target="_blank">regulamin</a>'
            }
            required
            errored={hasError("c29")}
            checked={checkboxes["2"]}
            onChange={setCheckbox(2)}
          />
        </div>

        <div className={styles.nextContainer}>
          <Back />
          <Button label={"Przejdź dalej"} onClick={nextStep} />
        </div>
      </div>
    </div>
  );
});

export default StepTwo;
