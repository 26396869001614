import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles/agreement.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import Loader from "_components/Loader";
import { useHistory } from "react-router";
import { requestGetAgreementPdf, requestSendSms } from "_api/transaction";

import { Document, Page } from "react-pdf";
import "./styles/pdf-overrides.css";
import { downloadFile } from "utils/functions";

import {useToasts} from "react-toast-notifications";

const Agreement = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { addToast } = useToasts();

  const [loading, setLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [isAgreement, setIsAgreement] = useState(false);
  const transaction_id = useSelector((state) => state.app.transaction_id);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setLoading(false);
  }

  useEffect(() => {
    dispatch(setCurrentStep(4));
    loadPdf();
    // eslint-disable-next-line
  }, [dispatch]);

  const getAgreementRequest = () => {
    setIsAgreement(true);
  };
  const loadPdf = async () => {
    try {
      const response = await requestGetAgreementPdf(transaction_id);
      setPdfFile(`data:application/pdf;base64,${response}`);
    } catch (e) {}
  };

  const getAgreement = async (e) => {
    e.preventDefault();
    setShowBackButton(true);
    setIsDownloading(true);
    try {
      await downloadFile(
        `/api/v1/transaction/agreement/${transaction_id}`,
        `umowa-najmu-${transaction_id}.zip`
      );
    } catch (e) {}
    setIsDownloading(false);
  };

  const acceptAgreement = async () => {
    setLoading(true);
    try {
      await requestSendSms(transaction_id);
      history.push("/akceptacja-umowy");
    } catch (e) {
      if(e.response?.status === 409){
        addToast("Limit SMS został przekroczony. Wniosek został zablokowany", {
          appearance: "error",
        });
      }
    }

    setLoading(false);
  };

  const backToAgreementScreen = () => {
    setIsAgreement(false);
  };

  return (
    <div>
      {isAgreement && (
        <div className={styles.agreementGet}>
          <h3 className={styles.title}>Pobieranie umowy</h3>
          <div className={styles.content}>
            <p>
              Twoja Umowa została zaszyfrowana i przygotowana do pobrania.
              <br />
              Kodem do odszyfrowania Twojej Umowy jest ostatnich 5 cyfr numeru
              PESEL
            </p>

            <div className={styles.nextContainer}>
              {!showBackButton && (
                <Button label={"Pobierz umowę"} onClick={getAgreement} />
              )}
              {showBackButton && (
                <Button label={"Wróć"} onClick={backToAgreementScreen} />
              )}
            </div>
          </div>
        </div>
      )}
      {!isAgreement && (
        <>
          <h3 className={styles.title}>Umowa</h3>
          <div className={styles.content}>
            <div className={styles.agreement}>
              {pdfFile && (
                <Document
                  file={pdfFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={""}
                >
                  {!loading &&
                    numPages &&
                    Array.from(Array(numPages).keys()).map((el, index) => (
                      <Page pageNumber={index + 1} />
                    ))}
                </Document>
              )}
              {loading && <Loader />}
            </div>
            {!loading && (
              <>
                <div className={styles.nextContainer}>
                  <Button
                    label={"Pobierz umowę"}
                    onClick={getAgreementRequest}
                    bordered
                    loading={isDownloading}
                  />
                  <Button
                    label={"Akceptuję umowę"}
                    loading={loading}
                    onClick={acceptAgreement}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default Agreement;
