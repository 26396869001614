import React, { memo, useEffect, useState } from "react";
import styles from "./styles/input.module.scss";
import cx from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/datepicker-overrides.css";
import pl from "date-fns/locale/pl";
import Checkbox from "_components/Checkbox";
import { useSelector } from "react-redux";
import { pathOr } from "ramda";

registerLocale("pl", pl);

const TextInput = memo(
  ({
    label,
    placeholder,
    onTextChange,
    name,
    type,
    value,
    disabled,
    error,
    optional,
    allowBack,
  }) => {
    const onChange = (e) => {
      setInitial(false);
      onTextChange(e.target.value);
    };
    const [startDate, setStartDate] = useState(null);
    const [transparent, setTransparent] = useState(false);
    const [isInitial, setInitial] = useState(true);
    const formItems = useSelector((state) => state.form);


    const setDate = (date) => {
      setInitial(false);
      const text = moment(date).format("YYYY-MM-DD");
      setStartDate(date);
      onTextChange(text);
      // dispatch(setFormItem({ name, value: text }));
    };

    const initialValue = pathOr(null, [name])(formItems);
    const currentValue = isInitial ? pathOr(value, [name])(formItems) : value;

    useEffect(() => {
      if (!optional) {
        setTransparent(false);
      }
    }, [optional]);

    useEffect(() => {

        if(transparent){
            onTextChange("")
        }
        // eslint-disable-next-line
    }, [transparent]);



    useEffect(() => {
      if (isInitial) {
        onTextChange(currentValue);
        if (currentValue) {
          if (type === "date" || type === "dateday") {
            setStartDate(new Date(currentValue));
          }
        }
      }
      // eslint-disable-next-line
    }, [isInitial]);

    useEffect(() => {
      if (initialValue && isInitial) {
        onTextChange(initialValue);
      }
      // eslint-disable-next-line
    }, [initialValue, isInitial]);

    // const onBlur = (e) => {
    //   dispatch(setFormItem({ name, value }));
    // };

    return (
      <div
        className={cx(styles.container, {
          [styles.errored]: !!error,
          [styles.transparent]: transparent,
        })}
      >
        {label && <label>{label}</label>}
        {type !== "date" && type !== "dateday" && (
          <input
            type={type}
            name={name}
            placeholder={transparent ? "" : placeholder}
            onChange={onChange}
            value={currentValue}
            disabled={disabled || transparent}
          />
        )}
        {type === "date" && (
          <DatePicker
            locale="pl"
            selected={startDate}
            onChange={setDate}
            dateFormat="MM/yy"
            showMonthYearPicker
            showFullMonthYearPicker
            showTwoColumnMonthYearPicker
            className={styles.datePicker}
            minDate={new Date()}
          />
        )}
        {type === "dateday" && (
          <DatePicker
            locale="pl"
            selected={startDate}
            onChange={setDate}
            dateFormat="yyyy-MM-dd"
            showYearDropdown
            scrollableYearDropdown={true}
            className={styles.datePicker}
            minDate={allowBack ? null : new Date()}
            maxDate={allowBack ? new Date() : null}
          />
        )}
        {!!error && <span className={styles.error}>{error}</span>}
        {optional && (
          <div className={styles.nipCheckboxContainer}>
            <Checkbox
              label={"Nie posiadam numeru NIP"}
              checked={transparent}
              onChange={setTransparent}
            />
          </div>
        )}
      </div>
    );
  }
);
TextInput.displayName = "TextInput";

TextInput.defaultProps = {
  label: null,
  placeholder: null,
  onTextChange: () => {},
  name: "name",
  type: "text",
  value: "",
  disabled: false,
  error: null,
  optional: false,
  allowBack: false,
};

export default TextInput;
