import React, { memo } from "react";
import { useSelector } from "react-redux";
import cx from "classnames";
import styles from "_containers/styles/navigator.module.scss";
import { steps } from "utils/constants";

const Navigator = memo(() => {
  const currentStep = useSelector((state) => state.app.currentStep);
  const hideNavigator = useSelector((state) => state.app.hideNavigator);

  const renderStep = ({ title, subtitle, num }) => (
    <li
      key={num}
      className={cx({
        [styles.done]: num < currentStep,
        [styles.active]: num === currentStep,
      })}
    >
      <div className={styles.name}>
        <div>{title}</div>
        <span>{subtitle}</span>
      </div>
      <div className={styles.numContainer}>
        <em>{num}</em>
      </div>
    </li>
  );
  if(hideNavigator){
      return null;
  }

  return (
    <nav className={styles.container}>
      <ul>
        {steps.map((step, index) => renderStep({ ...step, num: index + 1 }))}
      </ul>
    </nav>
  );
});

export default Navigator;
