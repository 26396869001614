import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";

import styles from "./styles/upload.module.scss";
import Button from "_components/Button";
import Loader from "_components/Loader";
import cx from "classnames";
import { toBase64 } from "utils/functions";
import { requestUploadStatement } from "_api/upload";
import { useToasts } from "react-toast-notifications";
import { pathOr } from "ramda";
import CheckMark from "_components/CheckMark";
import { useParams } from "react-router";
import { setConfirmEmail, setHideNavigator } from "_redux/actions/app";
import { requestCheckStatus } from "_api/transaction";

const UploadStatement = memo(({legalPerson}) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const { token1, token2 } = useParams();

  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [success, setSuccess] = useState(false);
  const [binaryStr, setBinaryStr] = useState([]);
  const [transaction_id, setTransaction_id] = useState(null);
  const [agreement_number, setAgreement_number] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);

  useEffect(() => {
    dispatch(setHideNavigator(true));
    loadData();
    // eslint-disable-next-line
  }, [dispatch]);

  const loadData = async () => {
    try {
      const response = await requestCheckStatus(token1, token2);
      const {
        token,
        transaction_id,
        agreement_number,
        transaction_person_name,
        transaction_person_surname,
      } = response;
      setTransaction_id(transaction_id);
      setAgreement_number(agreement_number);
      setName(transaction_person_name);
      setSurname(transaction_person_surname);
      dispatch(setConfirmEmail({ token }));
    } catch (e) {}
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setFiles((prev) => [...prev, ...acceptedFiles]);
      acceptedFiles.forEach(async (file) => {
        const base64 = await toBase64(file);
        setBinaryStr((prev) => [...prev, base64.split("base64,")[1]]);
      });
    }
  }, []);

  const uploadFile = async () => {
    setUploading(true);

    try {
      if (files.length > 0 && binaryStr.length > 0) {
        // binaryStr.map(async (str, index) => {
        for (let index = 0; index < binaryStr.length; index++) {
          let str = binaryStr[index];
          try {
            await requestUploadStatement({
              transaction_id,
              filename: files[index] ? files[index].name : "name.jpg",
              binaryStr: str,
            },legalPerson).catch((e) => {
              throw new Error(e.message);
            });
          } catch (e) {
            addToast(e.message, { appearance: "error" });
            setUploading(false);
            setLoading(false);
            return false;
          }

          if (index === binaryStr.length - 1) {
            setSuccess(true);
          }
        }
      }
    } catch (e) {
      const message = pathOr(null, ["response", "data", "data"])(e);
      const errors = pathOr([], ["response", "data", "errors"])(e);

      errors.forEach((e) => {
        addToast(e.message, {
          appearance: "error",
        });
      });

      if (message) {
        addToast(message, {
          appearance: "error",
        });
      }
      setUploading(false);
      setLoading(false);
    }
  };

  const removeFile = (file) => (e) => {
    console.log("tu");
    e.preventDefault();
    e.stopPropagation();
    setFiles((prev) => prev.filter((f) => f.path !== file.path));

    toBase64(file).then((base64) => {
      setBinaryStr((prev) =>
        prev.filter((f) => {
          // eslint-disable-next-line eqeqeq
          return base64.split("base64,")[1].length !== f.length;
        })
      );
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 3,
    accept: "image/jpeg, image/png, application/pdf",
  });
  return (
    <div className={styles.container}>
      {!success && (
        <>
          <h4>Prześlij zaświadczenie o {legalPerson? 'pełnomocnictwie' : 'zarobkach'}</h4>
          <p>
            {agreement_number && <div>Umowa numer: {agreement_number}</div>}
            {name && surname && <div>Wnioskodawca: {`${name} ${surname}`}</div>}
          </p>
          <div
            {...getRootProps()}
            className={cx(styles.dropzone, {
              [styles.selected]: files.length > 0,
            })}
          >
            <img src={require("../images/icon-upload.svg")} alt={"upload"} />
            <input {...getInputProps()} />
            {files.length === 0 && (
              <p>
                Przeciągnij i upuść tutaj lub <em>wgraj</em> z dysku
                <br />
                <span>(Pliki w formacie PDF, JPG, JPEG, PNG)</span>
              </p>
            )}
            {files.length > 0 &&
              files.map((file, index) => (
                <p key={index} className={styles.file}>
                  {file.name}
                  <span onClick={removeFile(file)}>x</span>
                </p>
              ))}
          </div>
          {(loading || uploading) && <Loader size="small" />}
          {!(loading || uploading) && (
            <div>
              <Button
                label={"Wyślij"}
                disabled={files.length === 0}
                onClick={uploadFile}
                loading={uploading}
              />
            </div>
          )}
        </>
      )}
      {success && (
        <div className={styles.successContainer}>
          <p>Dziękujemy!</p>
          <CheckMark />

          <p>Twoje zaświadczenie zostało przesłane.</p>
        </div>
      )}
    </div>
  );
});
UploadStatement.displayName = "UploadStatement";
UploadStatement.defualtProps = {
  legalPerson:false
}
export default UploadStatement;


export const UploadStatementLegalPerson = () => {

  return <UploadStatement legalPerson={true}/>

}
