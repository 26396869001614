export const SET_ITEM = "from/SET_ITEM";
export const SET_ITEMS = "from/SET_ITEMS";

export function setFormItem(payload) {
  return {
    type: SET_ITEM,
    payload,
  };
}


export function setFormItems(payload) {
  return {
    type: SET_ITEMS,
    payload,
  };
}

