import { SET_ITEM, SET_ITEMS } from "_redux/actions/form";

let initialState = {};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ITEM:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case SET_ITEMS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
