import React, { memo } from "react";
import cx from "classnames";
import styles from "./styles/radio-button.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setFormType } from "_redux/actions/app";

const RadioButton = memo(({ label, disabled, type }) => {
  const dispatch = useDispatch();
  const formType = useSelector((state) => state.app.formType);

  const checked = formType === type;

  const onChangeType = () => {
    dispatch(setFormType(type));
  };

  return (
    <label className={cx(styles.radio, { [styles.disabled]: disabled })}>
      <span className={styles.label}>{label}</span>
      <input
        type="radio"
        name="radio"
        disabled={disabled}
        checked={checked}
        onChange={onChangeType}
      />
      <span className={styles.checkmark} />
    </label>
  );
});

RadioButton.displayName = "RadioButton";
RadioButton.defaultProps = {
  label: "",
  disabled: false,
  checked: false,
};

export default RadioButton;
