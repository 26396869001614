import React, { memo } from "react";
import cx from "classnames";
import styles from "./styles/button.module.scss";

const Button = memo(({ label, onClick, bordered, loading, disabled,blank }) => {
  return (
    <button
      disabled={disabled}
      className={cx(styles.container, {
        [styles.bordered]: bordered,
        [styles.loading]: loading,
        [styles.disabled]: disabled,
        [styles.blank]: blank,
      })}
      onClick={onClick}
    >
      <span>{label}</span>
      {loading && <div className={styles.loader} />}
    </button>
  );
});
Button.displayName = "Button";

Button.defaultProps = {
  label: "name",
  onClick: null,
  bordered: false,
  loading: false,
  disabled: false,
    blank:false
};

export default Button;
