import React, { memo } from "react";
import cx from "classnames";
import styles from "./styles/back.module.scss";
import { useHistory } from "react-router-dom";

const Back = memo(({ label, disabled }) => {
  const history = useHistory();
  const onClick = () => {
    history.goBack();
  };

  return (
    <button
      disabled={disabled}
      className={cx(styles.container)}
      onClick={onClick}
    >
      <img src={require("../images/back.svg")} alt={"back"} />
      <span>{label}</span>
    </button>
  );
});
Back.displayName = "Back";

Back.defaultProps = {
  label: "Wstecz",
};

export default Back;
