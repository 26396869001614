import React, { memo } from "react";
import styles from "./styles/checkmark.module.scss";


const CheckMark = memo(() => {
  return (
    <div className={styles.container}/>
  );
});
CheckMark.displayName = "CheckMark";

export default CheckMark;
