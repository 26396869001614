import axios from "utils/axios";

export const requestUpload = async ({
  transaction_id,
  filename,
  binaryStr,
}) => {
  let formData = new FormData();

  formData.append("filename", filename);
  formData.append("binaryStr", binaryStr);
  return await axios
    .post(
      `/api/v1/transaction/step/5/upload/attachment/${transaction_id}`,
      formData
    )
    .then((res) => res.data).catch((error)=> {throw new Error(error.response && error.response.data && error.response.data.data)})

};

export const requestUploadStatement = async ({
  transaction_id,
  filename,
  binaryStr,
},legalPerson = false) => {
  let formData = new FormData();
  let pathUpload = legalPerson? 'legal-person' : 'natural-person';

  formData.append("filename", filename);
  formData.append("binaryStr", binaryStr);
  return await axios
    .post(
      `/api/v1/transaction/step/2/upload/statement/${pathUpload}/${transaction_id}`,
      formData
    )
    .then((res) => res.data)
      .catch((error)=> {throw new Error(error.response && error.response.data && error.response.data.data)})
}


