import axios from "utils/axios";

export const requestCalculateParams = async (
  main,
  months,
  accessories,
  services_before,
  services_during,
  shop_uuid
) => {
  let data = {
    main_items: [],
    accessories_items: [],
    services_before_items: [],
    services_during_items: [],
    months,
  };

  if (main) {
    data.main_items.push({ ...main, quantity: 1 });
  }

  if (accessories) {
    data.accessories_items = accessories;
  }

  if (services_before) {
    data.services_before_items.push({ ...services_before, quantity: 1 });
  }

  if (services_during) {
    data.services_during_items.push({ ...services_during, quantity: 1 });
  }

  try {
    return await axios
      .post(`/api/v1/init/calculator/rent-params/${shop_uuid}`, data)
      .then((res) => res.data);
  } catch (e) {}
};

export const requestSendEmail = async ({
  userEmail,
  rentParams,
  mainProduct,
  accessory,
  servicesBefore,
  servicesDuring,
  recaptcha,
}) => {
  let data = {
    c16: userEmail,
    rent_params: rentParams,
    recaptcha,
    products: {
      main_items: [{ ...mainProduct, quantity: 1 }],
      accessories_items: accessory && accessory.length > 0 ? accessory : [],
      services_before_items: servicesBefore
        ? [{ ...servicesBefore, quantity: 1 }]
        : [],
      services_during_items: servicesDuring
        ? [{ ...servicesDuring, quantity: 1 }]
        : [],
    },
  };
  return await axios
    .post(`/api/v1/init/send-email`, data)
    .then((res) => res.data);
};

export const requestConfirmEmail = async ({ token1, token2 }) => {
  return await axios
    .post(`/api/v1/init/confirm-email/${token1}/${token2}`, {})
    .then((res) => res.data);
};
