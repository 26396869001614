import React, { createRef, memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "./styles/agreement-accept.module.scss";
import Button from "_components/Button";
import { setCurrentStep } from "_redux/actions/app";
import Loader from "_components/Loader";
import { useHistory } from "react-router";
import { requestSendSms, requestVerifySmsCode } from "_api/transaction";

const AgreementAccept = memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState({ 1: "", 2: "", 3: "", 4: "" });

  const transaction_id = useSelector((state) => state.app.transaction_id);

  let inputRefs = [createRef(), createRef(), createRef(), createRef()];

  useEffect(() => {
    dispatch(setCurrentStep(4));
  }, [dispatch]);

  const onChange = (num) => (e) => {
    const value = e.target.value;
    setError(null);

    setCode((prev) => ({ ...prev, [num]: value }));
    if (num < 3 && value.length > 0) {
      inputRefs[num + 1].focus();
    }
  };

  const acceptAgreement = async () => {
    setLoading(true);
    try {
      await requestVerifySmsCode(
        transaction_id,
        `${code[0]}${code[1]}${code[2]}${code[3]}`
      );

      history.push(`/metoda-platnosci/wybierz/${transaction_id}`);
      setError(null);
    } catch (e) {
      if(e.response?.status === 409){
        setError("Limit SMS został przekroczony. Wniosek został zablokowany");
      }else{
        setError("Nieprawidłowy kod");
      }

    }
    setLoading(false);
  };

  const resendCode = async () => {
    setResending(true);
    try {
      await requestSendSms(transaction_id);
    } catch (e) {}
    setResending(false);
  };
  return (
    <div>
      <h3 className={styles.title}>Akceptacja umowy</h3>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className={styles.content}>
            <p>
              Na Twój numer telefonu wysłaliśmy SMS z kodem aktywacyjnym.
              <br />
              Aby zaakceptować Umowę przepisz go poniżej
            </p>

            <div className={styles.codeContainer}>
              {error && <span className={styles.error}>{error}</span>}
              <input
                name="input_0"
                ref={(r) => (inputRefs[0] = r)}
                type="text"
                maxLength={1}
                onChange={onChange(0)}
                value={code[0]}
              />
              <input
                name="input_1"
                ref={(r) => (inputRefs[1] = r)}
                type="text"
                maxLength={1}
                onChange={onChange(1)}
                value={code[1]}
              />
              <input
                name="input_2"
                ref={(r) => (inputRefs[2] = r)}
                type="text"
                maxLength={1}
                onChange={onChange(2)}
                value={code[2]}
              />
              <input
                name="input_3"
                ref={(r) => (inputRefs[3] = r)}
                type="text"
                maxLength={1}
                onChange={onChange(3)}
                value={code[3]}
              />
            </div>
            <div className={styles.nextContainer}>
              <Button
                label={"Akceptuję umowę oraz ogólne warunki najmu"}
                loading={loading}
                onClick={acceptAgreement}
              />
              <Button
                label={"Wyślij ponownie kod"}
                loading={resending}
                onClick={resendCode}
                blank
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export default AgreementAccept;
