import styles from "_pages/styles/step-three.module.scss";
import TextInput from "_components/TextInput";
import cx from "classnames";
import Button from "_components/Button";
import React from "react";
import Back from "_components/Back";

const NaturalPersonData = ({
  onTextChange,
  hasError,
  values,
  nextStep,
  loading,
}) => {
  return (
    <div>
      <h3 className={styles.title}>Dane Wnioskodawcy</h3>

      <div className={styles.inputsContainer}>
        <TextInput
          label={"PESEL Wnioskodawcy"}
          name={"c4"}
          onTextChange={onTextChange("c4")}
          value={values.c4}
          error={hasError("c4")}
        />
        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Seria i numer dowodu"}
            name={"c8"}
            onTextChange={onTextChange("c8")}
            value={values.c8}
            error={hasError("c8")}
          />
          <TextInput
            label={"Data ważności dowodu"}
            name={"c32"}
            onTextChange={onTextChange("c32")}
            value={values.c32}
            error={hasError("c32")}
            type={"dateday"}
          />
        </div>

        <div className={styles.divider}>Adres zamieszkania Wnioskodawcy</div>
        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Ulica i numer budynku"}
            name={"c10"}
            onTextChange={onTextChange("c10")}
            value={values.c10}
            error={hasError("c10")}
          />
          <TextInput
            label={"Nr lokalu"}
            name={"c36"}
            onTextChange={onTextChange("c36")}
            value={values.c36}
            error={hasError("c36")}
          />
        </div>
        <div className={cx(styles.inputGroup, styles.small)}>
          <TextInput
            label={"Miasto"}
            name={"c11"}
            onTextChange={onTextChange("c11")}
            value={values.c11}
            error={hasError("c11")}
          />
          <TextInput
            label={"Kod pocztowy"}
            name={"c12"}
            onTextChange={onTextChange("c12")}
            value={values.c12}
            error={hasError("c12")}
          />
        </div>

        <div className={styles.divider}>Dane zakładu pracy Wnioskodawcy</div>
        {/*<TextInput*/}
        {/*  label={"Pełna nazwa zakładu pracy Wnioskodawcy"}*/}
        {/*  name={"c20"}*/}
        {/*  onTextChange={onTextChange("c20")}*/}
        {/*  value={values.c20}*/}
        {/*  error={hasError("c20")}*/}
        {/*/>*/}
        <div className={cx(styles.inputGroup, styles.half)}>
          <TextInput
            label={"NIP"}
            name={"c22"}
            onTextChange={onTextChange("c22")}
            value={values.c22}
            error={hasError("c22")}
          />
          <TextInput
            label={"Data zatrudnienia u obecnego pracodawcy"}
            name={"c19"}
            onTextChange={onTextChange("c19")}
            value={values.c19}
            error={hasError("c19")}
            type={"dateday"}
            allowBack
          />
        </div>

        {/*<div className={styles.divider}>Adres zakładu pracy Wnioskodawcy</div>*/}
        {/*<div className={cx(styles.inputGroup, styles.small)}>*/}
        {/*  <TextInput*/}
        {/*    label={"Ulica i numer budynku"}*/}
        {/*    name={"c46"}*/}
        {/*    onTextChange={onTextChange("c46")}*/}
        {/*    value={values.c46}*/}
        {/*    error={hasError("c46")}*/}
        {/*  />*/}
        {/*  <TextInput*/}
        {/*    label={"Nr lokalu"}*/}
        {/*    name={"c49"}*/}
        {/*    onTextChange={onTextChange("c49")}*/}
        {/*    value={values.c49}*/}
        {/*    error={hasError("c49")}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div className={cx(styles.inputGroup, styles.small)}>*/}
        {/*  <TextInput*/}
        {/*    label={"Miasto"}*/}
        {/*    name={"c47"}*/}
        {/*    onTextChange={onTextChange("c47")}*/}
        {/*    value={values.c47}*/}
        {/*    error={hasError("c47")}*/}
        {/*  />*/}
        {/*  <TextInput*/}
        {/*    label={"Kod pocztowy"}*/}
        {/*    name={"c48"}*/}
        {/*    onTextChange={onTextChange("c48")}*/}
        {/*    value={values.c48}*/}
        {/*    error={hasError("c48")}*/}
        {/*  />*/}
        {/*</div>*/}
        <TextInput
          label={"Numer telefonu"}
          name={"c21"}
          onTextChange={onTextChange("c21")}
          value={values.c21}
          error={hasError("c21")}
        />
      </div>
      <div className={styles.nextContainer}>
        <Back />
        <Button label={"Przejdź dalej"} onClick={nextStep} loading={loading} />
      </div>
    </div>
  );
};

export default NaturalPersonData;
